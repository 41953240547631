import * as React from 'react'
import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area'
import { cn } from '../../lib/utils'
import { RefObject, useMemo, useState, useEffect } from 'react'

const ScrollArea = React.forwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.Root> &
    ({ maxWidth?: number | string } | { maxHeight?: number | string }) & {
      viewportRef?: RefObject<HTMLDivElement>
      scrollThumbClassName?: string
    }
>(({ className, children, viewportRef, scrollThumbClassName, ...props }, ref) => {
  const sizingStyle = useMemo(() => {
    if ('maxWidth' in props) {
      return {
        maxWidth: typeof props.maxWidth === 'number' ? `${props.maxWidth}px` : props.maxWidth,
        height: '100%',
      }
    }

    if ('maxHeight' in props) {
      return {
        maxHeight: typeof props.maxHeight === 'number' ? `${props.maxHeight}px` : props.maxHeight,
        width: '100%',
      }
    }

    return {}
  }, [props])

  return (
    <ScrollAreaPrimitive.Root
      ref={ref}
      className={cn('relative overflow-hidden', className)}
      {...props}
    >
      <ScrollAreaPrimitive.Viewport
        ref={viewportRef}
        className='rounded-[inherit] [&>div]:!block'
        style={sizingStyle}
      >
        {children}
      </ScrollAreaPrimitive.Viewport>
      <ScrollBar scrollThumbClassName={scrollThumbClassName} />
      <ScrollAreaPrimitive.Corner />
    </ScrollAreaPrimitive.Root>
  )
})
ScrollArea.displayName = ScrollAreaPrimitive.Root.displayName

const ScrollBar = React.forwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>,
  React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar> & {
    scrollThumbClassName?: string
  }
>(({ className, orientation = 'vertical', scrollThumbClassName, ...props }, ref) => {
  return (
    <ScrollAreaPrimitive.ScrollAreaScrollbar
      ref={ref}
      orientation={orientation}
      className={cn(
        'flex touch-none select-none transition-colors',
        orientation === 'vertical' && 'h-full w-2.5 border-l border-l-transparent p-[1px]',
        orientation === 'horizontal' && 'h-2.5 flex-col border-t border-t-transparent p-[1px]',
        className,
      )}
      {...props}
    >
      <ScrollAreaPrimitive.ScrollAreaThumb
        className={cn('relative flex-1 rounded-full bg-neutral-200', scrollThumbClassName)}
      />
    </ScrollAreaPrimitive.ScrollAreaScrollbar>
  )
})
ScrollBar.displayName = ScrollAreaPrimitive.ScrollAreaScrollbar.displayName

const ScrollAreaGradient = ({ scrollAreaRef }: { scrollAreaRef: RefObject<HTMLDivElement> }) => {
  const [isOverflowing, setIsOverflowing] = useState(false)

  useEffect(() => {
    const checkOverflow = () => {
      if (scrollAreaRef.current) {
        const scrollArea = scrollAreaRef.current

        // Check if the scroll area has content that overflows its client height
        const hasOverflow = scrollArea.scrollHeight - scrollArea.clientHeight > 0

        // Check if the scroll area is scrolled to the bottom
        const isScrolledToBottom =
          scrollArea.scrollTop + scrollArea.clientHeight >= scrollArea.scrollHeight - 36

        // Set the state to true only if there is overflow and the area is not scrolled to the bottom
        setIsOverflowing(hasOverflow && !isScrolledToBottom)
      }
    }

    const scrollArea = scrollAreaRef.current

    // Create ResizeObserver to observe changes in dimensions
    const resizeObserver = new ResizeObserver(checkOverflow)

    if (scrollArea) {
      // Attach ResizeObserver
      resizeObserver.observe(scrollArea)

      // Attach scroll event listener
      scrollArea.addEventListener('scroll', checkOverflow)

      // Initial check
      checkOverflow()
    }

    // Cleanup on unmount
    return () => {
      if (scrollArea) {
        resizeObserver.disconnect()
        scrollArea.removeEventListener('scroll', checkOverflow)
      }
    }
  }, [scrollAreaRef])

  if (!isOverflowing) {
    return null
  }

  return (
    <div
      className={cn(
        'w-full h-16 absolute bottom-0',
        'bg-gradient-to-b from-transparent to-[#46527C1A]',
      )}
    />
  )
}

export { ScrollArea, ScrollBar, ScrollAreaGradient }
