import { createWithEqualityFn } from 'zustand/traditional'
import { AxiosInstance } from 'axios'
import { ValuecaseAuth, ValuecaseAuthState } from '@valuecase/common'
import { Permission } from '@valuecase/seller-backend'

export type PermissionCheckers = {
  hasPermissions: (...requiredPermissions: Permission[]) => boolean
  hasPermission: (requiredPermission: Permission) => boolean
  hasSomePermission: (...permissions: Permission[]) => boolean
}

export type AuthState = ValuecaseAuthState & {
  loading: boolean
  reloading: boolean
  initialLoadingDone: boolean
  isLocked: boolean
} & PermissionCheckers

export const useAuthState = createWithEqualityFn<AuthState>((set, get) => ({
  loading: true,
  reloading: false,
  initialLoadingDone: false,
  authorized: false,
  sub: undefined,
  isLocked: false,
  hasPermissions: (...requiredPermissions) => {
    const state = get()

    return (
      state.authorized &&
      requiredPermissions.every((requiredPermission) => {
        return state.permissions.some(
          (assignedPermission) => assignedPermission === requiredPermission,
        )
      })
    )
  },
  hasPermission: (requiredPermission) => {
    return get().hasPermissions(requiredPermission)
  },
  hasSomePermission: (...permissions) => {
    const state = get()

    return (
      state.authorized &&
      permissions.some((requiredPermission) => {
        return state.permissions.some(
          (assignedPermission) => assignedPermission === requiredPermission,
        )
      })
    )
  },
}))

export const Auth = new ValuecaseAuth({
  sellerApiBasePath: 'dashboard/api',
  app: 'dashboard',
})
Auth.listenForAuthChanges(updateAuthState)

export function createAuthReturnPath() {
  return window.location.pathname + window.location.search + window.location.hash
}

function updateAuthState() {
  const state = useAuthState.getState()
  if (state.initialLoadingDone) {
    useAuthState.setState({ reloading: true })
  } else {
    useAuthState.setState({ loading: true })
  }

  Auth.getAuthState()
    .then((authState) => {
      useAuthState.setState(authState)
    })
    .catch(() => {
      useAuthState.setState({
        authorized: false,
      })
    })
    .finally(() => {
      useAuthState.setState({
        loading: false,
        reloading: false,
        initialLoadingDone: true,
      })
    })
}

// Updating auth state initially
updateAuthState()

export function addAccessTokenRequestInterceptor(axiosInstance: AxiosInstance) {
  axiosInstance.interceptors.request.use((config) => {
    return Auth.getAccessToken()
      .then((accessToken) => {
        if (accessToken) {
          const headers = config.headers || {}
          headers['Authorization'] = `Bearer ${accessToken}`
          config.headers = headers
        }
        return config
      })
      .catch(() => {
        return config
      })
  })
}

export function getSubWithoutPrefix(auth: AuthState): string | undefined {
  if (!auth.authorized) {
    return
  }
  return auth.sub.startsWith('bubble|') ? auth.sub.substring(7) : auth.sub
}
