import { useQuery } from '@tanstack/react-query'
import { spacesApi } from '../../../api/spacesApi/spacesAPIclient'

export type NotificationsSettings = {
  newComments: boolean
  firstVisit: boolean
  actionPlanActivities: boolean
  dailyDigest: boolean
  weeklyDigest: boolean
  buyerFilesBlockUpload: boolean
  buyerCompletedForm: boolean
}

function readUserNotificationSettingsFn() {
  return () =>
    spacesApi.get<NotificationsSettings>(`/sellers/notificationsSettings`).then((res) => res.data)
}

export function useReadUserNotificationSettings() {
  const query = useQuery({
    queryKey: ['readUserNotificationSettings'],
    queryFn: readUserNotificationSettingsFn(),
  })
  return {
    isLoading: query.isLoading,
    isError: query.isError,
    error: query.error,
    notificationSettings: query.data,
    isFetched: query.isFetched,
  }
}
