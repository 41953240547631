import { useOverlay, SimpleModal, ModalControls } from '@valuecase/ui-components'
import { useCallback, useRef } from 'react'
import { SpaceCreationFlowWrapper } from '../components/spaceCreationFlow/SpaceCreationFlowWrapper'

export const useOpenCreateSpaceModal = () => {
  const overlay = useOverlay()
  const controls = useRef<ModalControls | null>(null)

  const handleOnCloseCallback = useCallback(() => {
    const queryString = window.location.search
    if (queryString) {
      const urlParams = new URLSearchParams(queryString)
      const createSpaceFromTemplate = urlParams.get('createSpaceFromTemplate')

      if (createSpaceFromTemplate) {
        urlParams.delete('createSpaceFromTemplate')
      }

      if (urlParams.size === 0) {
        window.history.replaceState({}, '', `${window.location.pathname}`)
      } else {
        window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`)
      }
    }
  }, [])

  const openCreateSpaceModal = useCallback(() => {
    return overlay.show(
      <SimpleModal
        controlsRef={controls}
        additionalStyleClasses={{
          layout: 'spacecreationflow-modal',
          baseModal: 'spacecreationflow-modal__base',
        }}
        body={<SpaceCreationFlowWrapper />}
        onCloseCallback={handleOnCloseCallback}
        maxWidth='978px'
        wide={true}
      />,
    )
  }, [overlay, handleOnCloseCallback])

  return { openCreateSpaceModal }
}
