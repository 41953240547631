import * as React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from '../../lib/utils'
import { IonIcon } from '../../Icons/IonIcon'

const alertVariants = cva('relative w-full rounded-lg p-4', {
  variants: {
    variant: {
      success: 'bg-success-s2 text-grey-s5',
    },
  },
  defaultVariants: {
    variant: 'success',
  },
})

const iconVariants = cva('absolute left-4 top-4 w-4 h-4', {
  variants: {
    variant: {
      success: 'text-success-s4',
    },
  },
  defaultVariants: {
    variant: 'success',
  },
})

const Alert = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof alertVariants> & { iconName?: string }
>(({ className, variant, iconName, children, ...props }, ref) => (
  <div ref={ref} role='alert' className={cn(alertVariants({ variant }), className)} {...props}>
    {iconName && (
      <IonIcon
        name={iconName}
        className={cn(iconVariants({ variant }), '[&~*]:pl-7 [&+div]:translate-y-[-3px]')}
      />
    )}
    {children}
  </div>
))
Alert.displayName = 'Alert'

const AlertTitle = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLHeadingElement>>(
  ({ className, ...props }, ref) => (
    <h5
      ref={ref}
      className={cn('mb-1 text-sm font-bold leading-none tracking-tight text-grey-s6', className)}
      {...props}
    />
  ),
)
AlertTitle.displayName = 'AlertTitle'

const AlertDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('text-sm [&_p]:leading-relaxed text-grey-s5', className)}
    {...props}
  />
))
AlertDescription.displayName = 'AlertDescription'

export { Alert, AlertTitle, AlertDescription }
