import {
  ColumnDef,
  PaginationState,
  SortingState,
  VisibilityState,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  useReactTable,
} from '@tanstack/react-table'
import {
  Button,
  getInitialsLogoUrl,
  IonIcon,
  MenuDropdown,
  MenuDropdownItem,
  MenuDropdownSeparator,
  ModalControls,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  useOverlay,
  usePagination,
} from '@valuecase/ui-components'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { getSubWithoutPrefix, useAuthState } from '../../../../auth/auth'
import { copyToClipboard } from '../../../../utils/ClipboardUtils'
import { useNotifications } from '../../../../utils/Notifications/Notifications'
import { ReadSpacesListDTO } from '../../hooks/types'
import SpaceNameCell from './columns/spaceName/SpaceName.cell'

import { useReadSalesforceIntegrationStatus } from '@/modules/integrations/salesforce/useReadSalesforceIntegrationStatus'
import { useReadTemplates } from '@/modules/templates/hooks/useReadTemplates'
import { VLoaderAnimationFullSizeCentered } from '@/ui-components/VLoader/VLoader'
import { cn } from '@/utils/Cn'
import {
  EngagementScoreSummary,
  ScoreDescriptor,
  formatForceEnglishDateWithLabel,
  getCompanyInitials,
  mapScoreDescriptorToLabel,
  mapScoreToDescriptor,
} from '@valuecase/common'
import { DataTable } from '@valuecase/ui-components'
import { Badge, BadgeVariants } from '@valuecase/ui-components/src/components/ui/badge'
import { Progress } from '@valuecase/ui-components/src/components/ui/progress'
import { DateTime } from 'luxon'
import styled from 'styled-components'
import { useHasSomePermission } from '../../../../auth/permissions'
import { useLDflags } from '../../../../launchdarkly/Launchdarkly'
import { useTrackEvent } from '../../../../mixpanel/useTrackEvent'
import ConfirmDeleteModal from '../../../../ui-components/ConfirmDeleteModal'
import { DisabledMenuDropdownItem } from '../../../../ui-components/DisabledMenuDropdownItem'
import { useChangeSpaceOwnerModalHook } from '../../../../ui-components/modal/space/ChangeSpaceOwner'
import { useReadHubSpotIntegrationStatus } from '../../../integrations/hubspot/useReadHubSpotIntegrationStatus'
import TemplateFromSpaceCreatorModal from '../../../templates/TemplateFromSpaceCreator/TemplateFromSpaceCreatorModal'
import { useCurrentTenantQuery } from '../../../tenant/hooks/useReadTenant'
import { useChangeSpacedArchivedStatus } from '../../hooks/useChangeSpacedArchivedStatus'
import { useDeleteSpace } from '../../hooks/useDeleteSpace'
import { SpaceOwner, useReadAvailableSpaceOwners } from '../../hooks/useReadAvailableSpaceOwners'
import { SpaceFilterSorting, SpaceQuerySortBy, useSpacesQuery } from '../../hooks/useReadSpaces'
import { useSpaceFilter } from '../../hooks/useSpaceFiltering'
import NoSpacesFound from '../NoSpacesFound'
import { LinkToHubSpotTrigger } from '../linkSpaceToHubSpotFlow/LinkToHubSpotTrigger'
import { LinkToSalesforceTrigger } from '../linkSpaceToSalesforceFlow/LinkToSalesforceTrigger'
import { SpaceArchivingTrigger } from '../spaceArchivingFlow/SpaceArchivingTrigger'
import SpacesPageHeader from '../spaceModuleHeader/SpacesPageHeader'
import FilterContainer from './filters/FilterContainer'
import TeamsColumn from '@/modules/teams/TeamsColumn'
import EditSpaceTeamsDialog from './dialogs/EditSpaceTeamsDialog'
import { Button2 } from '@valuecase/ui-components/src/components/ui/button'

interface Props {
  spaces: (ReadSpacesListDTO & {
    ownerName: string
    ownerEmail?: string
    picture?: string
    teams?: {
      id: string
      name: string
    }[]
  })[]
  availableSpaceOwners: SpaceOwner[]
  pageIndex: number
  pageSize: number
  totalPages: number
  isLoading: boolean
  setPagination: (pagination: PaginationState) => void
}

type MAPStatus = ReadSpacesListDTO['actionPlanSummary']['status']

export const mapActionPlanStatusToStyleVariant = (
  status: MAPStatus,
): Extract<BadgeVariants, 'neutral' | 'attention' | 'info' | 'danger' | 'success'> => {
  switch (status) {
    case 'notCreated':
      return 'neutral'
    case 'notStarted':
      return 'neutral'
    case 'stalled':
      return 'attention'
    case 'inProgress':
      return 'info'
    case 'overdue':
      return 'danger'
    case 'completed':
      return 'success'
    default:
      throw new Error(`unknown action plan status: ${status}`)
  }
}

export const mapActionPlanStatusToLabel = (status: MAPStatus): string => {
  switch (status) {
    case 'notCreated':
      return 'Not created'
    case 'notStarted':
      return 'Not started'
    case 'stalled':
      return 'Stalled'
    case 'inProgress':
      return 'In progress'
    case 'overdue':
      return 'Overdue'
    case 'completed':
      return 'Completed'
    default:
      throw new Error(`unknown action plan status: ${status}`)
  }
}

export type SpacesListDto = Props['spaces'][number]

const ProfileImage = styled.img`
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid var(--theme-grey-s2);
  box-sizing: border-box;
  object-fit: cover;
`

const OwnerCell = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--theme-grey-s6);
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`

const STORAGE_COLUMN_VISIBILITY_KEY = 'spacesTable_columnVisibility'

const useLocalStorage = () => {
  const setJSONValue = (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value))
  }

  const getJSONValue = (key: string) => {
    const value = localStorage.getItem(key)
    return value ? JSON.parse(value) : null
  }

  return {
    setJSONValue,
    getJSONValue,
  }
}

const EngagementScoreCell = ({
  engagementSummary,
  activityCountInLast14Days,
  spaceLinkToActivityFeed,
}: {
  engagementSummary: EngagementScoreSummary
  activityCountInLast14Days: number
  spaceLinkToActivityFeed: string
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false)

  const { score, scoreBreakdown, inputs } = engagementSummary

  const scoreDescriptor = useMemo(
    () => mapScoreToDescriptor(score, activityCountInLast14Days),
    [score, activityCountInLast14Days],
  )

  const scoreDescriptorLabel = useMemo(
    () => mapScoreDescriptorToLabel(scoreDescriptor),
    [scoreDescriptor],
  )

  const scoreIcon = useCallback((score: ScoreDescriptor, className?: string) => {
    switch (score) {
      case 'high':
        return (
          <IonIcon
            name={'c_engagement-high'}
            className={cn('w-4 h-4 text-success-s4', className)}
          />
        )

      case 'medium':
        return (
          <IonIcon
            name={'c_engagement-medium'}
            className={cn('w-4 h-4 text-[#FDBA74]', className)}
          />
        )
      case 'low':
        return (
          <IonIcon name={'c_engagement-low'} className={cn('w-4 h-4 text-warning-s4', className)} />
        )
    }
  }, [])

  const sessionCount = useMemo(() => inputs?.sessionCount ?? 0, [inputs?.sessionCount])

  const activitiesPerVisit = useMemo(
    () => Math.floor((inputs?.nonSessionActivityCount ?? 0) / (inputs?.sessionCount || 1)),
    [inputs?.nonSessionActivityCount, inputs?.sessionCount],
  )

  const nonSessionOrViewActivityCount = useMemo(
    () => inputs?.nonSessionOrViewActivityCount ?? 0,
    [inputs?.nonSessionOrViewActivityCount],
  )

  const stakeholdersCount = useMemo(
    () => inputs?.activityStakeholders ?? 0,
    [inputs?.activityStakeholders],
  )

  if (score === undefined || scoreDescriptor === 'n/a') {
    return <div className={'flex items-center '}>-</div>
  }

  return (
    // use modal here to prevent clicking on the row itself when open
    <Popover modal open={popoverOpen} onOpenChange={setPopoverOpen}>
      <PopoverTrigger
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <div
          className={
            'flex gap-2 items-center w-full h-full justify-center hover:bg-grey-s2 p-2 rounded-lg transition-colors'
          }
        >
          {scoreIcon(scoreDescriptor)}
          {scoreDescriptorLabel}
        </div>
      </PopoverTrigger>
      <PopoverContent
        onClick={(e) => {
          // prevents the row click to open the space itself
          e.stopPropagation()
        }}
      >
        <div className={'flex flex-col gap-4'}>
          <div className={'flex gap-2 mx-2'}>
            {scoreIcon(scoreDescriptor, 'w-8 h-8')}
            <div className={'flex flex-col gap-1'}>
              <h3 className={'font-bold'}>{scoreDescriptorLabel} engagement</h3>
              <p className={'text-xs text-grey-s5'}>in the last 14 days</p>
            </div>
          </div>

          <div className={'flex justify-between items-center mx-2'}>
            <p className={'text-sm'}>
              {sessionCount} {sessionCount === 1 ? 'visit' : 'visits'}
            </p>
            {scoreIcon(mapScoreToDescriptor(scoreBreakdown.sessionScore))}
          </div>

          <div className={'flex justify-between items-center mx-2'}>
            <p className={'text-sm'}>
              {activitiesPerVisit} {activitiesPerVisit === 1 ? 'activity' : 'activities'} per visit
            </p>
            {scoreIcon(mapScoreToDescriptor(scoreBreakdown.activitiesPerVisitScore))}
          </div>

          <div className={'flex justify-between items-center mx-2'}>
            <p className={'text-sm'}>
              {nonSessionOrViewActivityCount} non page-view{' '}
              {nonSessionOrViewActivityCount === 1 ? 'interaction' : 'interactions'}
            </p>
            {scoreIcon(mapScoreToDescriptor(scoreBreakdown.activitiesScore))}
          </div>

          <div className={'flex justify-between items-center mx-2'}>
            <p className={'text-sm'}>
              {stakeholdersCount} unique active{' '}
              {stakeholdersCount === 1 ? 'stakeholder' : 'stakeholders'}
            </p>
            {scoreIcon(mapScoreToDescriptor(scoreBreakdown.stakeholdersScore))}
          </div>

          <Button
            label={'Go to Space Activity'}
            style={'secondary'}
            fullWidth
            size={'small'}
            iconPosition={'right'}
            icon={'arrow-forward'}
            onClick={() => {
              window.location.href = spaceLinkToActivityFeed
            }}
          />
          <a
            href={'https://help.valuecase.de/en/articles/136387-how-our-engagement-score-works'}
            target={'_blank'}
            onClick={(e) => e.stopPropagation()}
            className={
              'flex gap-1 text-grey-s4 hover:text-grey-s5 transition-colors text-xs font-semibold items-center w-fit'
            }
            rel='noreferrer'
          >
            <IonIcon name={'help-circle-outline'} className={'w-3 h-3 text-grey-s5'} />
            Learn more
          </a>
        </div>
      </PopoverContent>
    </Popover>
  )
}

const SpacesList = () => {
  const { success, error, asyncNotification } = useNotifications()
  const { trackEvent } = useTrackEvent()
  const auth = useAuthState()
  const { flags, isFetched: isFlagsFetched } = useLDflags()
  const { tenant } = useCurrentTenantQuery()
  const { hubSpotIntegrationStatus } = useReadHubSpotIntegrationStatus()
  const hasHubSpotIntegration = hubSpotIntegrationStatus === 'enabled'
  const { salesforceIntegrationStatus } = useReadSalesforceIntegrationStatus()
  const hasSalesforceIntegration = salesforceIntegrationStatus === 'enabled'
  const overlay = useOverlay()
  const modalControls = useRef<ModalControls | null>(null)
  const { deleteSpaceAsync } = useDeleteSpace()
  const [spaceSelectedForDeletion, selectSpaceForDeletion] = useState<SpacesListDto>()
  const [showWaitingForLoadAnimation, setShowWaitingForLoadAnimation] = useState(false)
  const [showDeleteSpaceModal, setShowDeleteSpaceModal] = useState(false)
  const { changeSpaceArchivedStatus } = useChangeSpacedArchivedStatus()
  const { resetFilters } = useSpaceFilter()
  const storage = useLocalStorage()
  const { availableSpaceOwners } = useReadAvailableSpaceOwners()
  const [spaceSelectedForTeamEditing, setSpaceSelectedForTeamEditing] =
    useState<SpacesListDto | null>(null)
  const showCreateTemplateFromSpaceOverlay = useCallback(
    (spaceId: string) => {
      overlay.show(<TemplateFromSpaceCreatorModal controlsRef={modalControls} spaceId={spaceId} />)
    },
    [overlay],
  )

  const onArchiveClick = useCallback(
    async (spaceId: string) => {
      await asyncNotification(
        changeSpaceArchivedStatus(spaceId, true),
        'Space successfully archived',
        'There was an error archiving the space. Please try again later.',
      )
    },
    [asyncNotification, changeSpaceArchivedStatus],
  )

  const { pageIndex, pageSize, setPagination, resetPagination } = usePagination()
  const { filters } = useSpaceFilter()
  const { unarchivedTemplates: templates } = useReadTemplates()

  const [sorting, setSorting] = useState<SortingState>([{ id: 'createdAt', desc: true }])

  const serverSideSorting = useMemo((): SpaceFilterSorting => {
    return {
      sortBy: (sorting[0]?.id as SpaceQuerySortBy) ?? 'createdAt',
      sortDirection: sorting[0]?.desc ? 'desc' : 'asc',
    }
  }, [sorting])
  const {
    spaces: spaceData,
    isLoading,
    isFetching,
    totalPages,
    totalCount,
  } = useSpacesQuery({
    filters,
    sorting: serverSideSorting,
    // Table uses 0-based index, API uses 1-based index
    page: pageIndex + 1,
    pageSize: pageSize,
    includeSpaceScore: !!flags && !!flags['space-engagement-scoring'],
    enabled: isFlagsFetched,
    useUrlSearchParams: true,
  })

  const getIdWithoutPrefix = useMemo(
    () => (id: string) => {
      return id.replace(/^bubble\|/, '')
    },
    [],
  )

  const spaces = useMemo(() => {
    return spaceData?.map((space) => {
      const owner = availableSpaceOwners?.find(
        (owner) => getIdWithoutPrefix(owner.id) === space.ownerId,
      )

      let ownerName = ''
      if (owner?.firstName && owner?.lastName) {
        ownerName = `${owner.firstName} ${owner.lastName}`
      } else if (owner?.lastName) {
        ownerName = owner.lastName
      } else if (owner?.firstName) {
        ownerName = owner.firstName
      }
      return {
        ...space,
        ownerName,
        ownerEmail: owner?.email,
        picture: owner?.picture,
      }
    })
  }, [getIdWithoutPrefix, spaceData, availableSpaceOwners])

  const getUrlWithToken = useCallback(
    (spaceId: string, token?: string, showActivityFeed?: boolean): string => {
      const url = new URL(location.href)

      url.pathname = `/spaces/${spaceId}`
      url.search = ''

      if (token) {
        url.searchParams.set('token', token)
      }

      if (showActivityFeed) {
        url.searchParams.set('openActivityFeed', 'true')
      }

      return url.href
    },
    [],
  )

  const onCopyLinkToSpace = useMemo(
    () => (id: string, token?: string) => {
      const redirectTo = getUrlWithToken(id, token)
      copyToClipboard(redirectTo)
      success('Link to space copied to your clipboard')
    },
    [getUrlWithToken, success],
  )

  const { show: showChangeSpaceOwnerModal } = useChangeSpaceOwnerModalHook()
  const hasPermissionToChangeSpaceOwner = useHasSomePermission(
    'USERS_MANAGE',
    'SPACES_ALL_ADMINISTRATION',
  )
  const isAllowedToChangeSpaceOwner = useMemo(
    () => (space: ReadSpacesListDTO) => {
      return (
        availableSpaceOwners &&
        availableSpaceOwners.length > 0 &&
        (hasPermissionToChangeSpaceOwner || space.ownerId === getSubWithoutPrefix(auth))
      )
    },
    [availableSpaceOwners, hasPermissionToChangeSpaceOwner, auth],
  )

  const isAllowedToSaveAsTemplate = useHasSomePermission(
    'SPACES_ALL_ADMINISTRATION',
    'TEMPLATES_MANAGE',
  )
  const hasPermissionToArchive = useHasSomePermission('SPACES_ALL_ADMINISTRATION')
  const isAllowedToArchiveSpace = useMemo(
    () => (space: ReadSpacesListDTO) =>
      (space.ownerId && space.ownerId === getSubWithoutPrefix(auth)) || hasPermissionToArchive,
    [auth, hasPermissionToArchive],
  )

  const columns = useMemo<ColumnDef<Props['spaces'][number]>[]>(() => {
    const columns: ColumnDef<Props['spaces'][number]>[] = [
      {
        id: 'companyName',
        accessorKey: 'companyName',
        header: 'Company Name',
        enableHiding: false,
        cell: (ctx) => {
          const initials = getCompanyInitials(ctx.row.original.companyName)?.toLowerCase()
          const spaceLogo = ctx.row.original.companyLogo
            ? ctx.row.original.companyLogo
            : getInitialsLogoUrl(initials)
          return (
            // todo: replace with AvatarThumbnail
            <SpaceNameCell
              className={'h-10'}
              archived={ctx.row.original.archived}
              logoUrl={spaceLogo}
              parentTemplateName={ctx.row.original.parentTemplateName || ''}
              spaceName={ctx.renderValue()}
            />
          )
        },
        size: 300,
      },
    ]
    if (isFlagsFetched && flags && flags['space-engagement-scoring']) {
      const scoreColumnDefinition: ColumnDef<Props['spaces'][number]> = {
        id: 'score',
        header: 'Engagement (14d)',
        minSize: 142, // Ensure header fits on 1 line
        accessorKey: 'engagementSummary.score',
        enableSorting: true,
        cell: ({
          row: {
            original: { engagementSummary, rootNodeId },
          },
        }) => {
          return (
            <EngagementScoreCell
              engagementSummary={engagementSummary}
              activityCountInLast14Days={engagementSummary?.inputs?.allActivityCount ?? 0}
              spaceLinkToActivityFeed={getUrlWithToken(rootNodeId, undefined, true)}
            />
          )
        },
        size: 120,
      }
      columns.push(scoreColumnDefinition)
    }
    columns.push(
      {
        id: 'templateName',
        enableHiding: false,
        accessorFn: (originalRow) => {
          return originalRow.parentTemplateName || 'Deleted Template'
        },
      },
      {
        id: 'mapStatus',
        header: 'Status',
        accessorKey: 'actionPlanSummary.status',
        enableSorting: false,
        cell: ({
          row: {
            original: {
              actionPlanSummary: { status },
            },
          },
        }) => {
          return (
            <Tooltip>
              <TooltipTrigger>
                <Badge variant={mapActionPlanStatusToStyleVariant(status)}>
                  {mapActionPlanStatusToLabel(status)}
                </Badge>
              </TooltipTrigger>
              {status === 'stalled' && (
                <TooltipContent>No task completed in the last 2 weeks.</TooltipContent>
              )}
            </Tooltip>
          )
        },
        size: 120,
      },
      {
        id: 'mapProgress',
        header: 'Progress',
        accessorKey: 'actionPlanSummary.progress',
        cell: ({
          row: {
            original: {
              actionPlanSummary: { status, progress },
            },
          },
        }) => {
          if (status === 'notCreated') {
            return '-'
          }
          return (
            <Progress variant={mapActionPlanStatusToStyleVariant(status)} value={progress * 100} />
          )
        },
        size: 130,
      },
      {
        id: 'createdAt',
        accessorKey: 'createdAt',
        header: 'Created',
        cell: (ctx) => (
          <span className='capitalize'>
            {formatForceEnglishDateWithLabel(
              DateTime.fromISO(ctx.renderValue() as string),
              'short',
              tenant?.payload?.locale,
            )}
          </span>
        ),
        maxSize: 80,
      },
      {
        id: 'updatedAt',
        accessorKey: 'updatedAt',
        header: 'Last changed',
        cell: (ctx) => (
          <span className='capitalize'>
            {formatForceEnglishDateWithLabel(
              DateTime.fromISO(ctx.renderValue() as string),
              'short',
              tenant?.payload?.locale,
            )}
          </span>
        ),
        maxSize: 100,
      },
      {
        id: 'ownerId',
        accessorKey: 'ownerId',
        header: 'Owner',
        cell: (ctx) => (
          <OwnerCell>
            {ctx.row.original.picture && <ProfileImage src={ctx.row.original.picture} />}
            {ctx.row.original.ownerName}
          </OwnerCell>
        ),
        minSize: 150,
      },
      {
        id: 'lastVisit',
        accessorKey: 'lastVisit',
        header: 'Last Visit',
        cell: (ctx) => {
          if (!ctx.row.original.visits || ctx.row.original.visits === 0) {
            return '-'
          }
          const renderedValue = ctx.renderValue()
          if (
            !renderedValue ||
            typeof renderedValue !== 'string' ||
            isNaN(Date.parse(renderedValue))
          ) {
            return 'More than 14 days ago'
          }

          return (
            <span className='capitalize'>
              {formatForceEnglishDateWithLabel(
                DateTime.fromISO(renderedValue),
                'short',
                tenant?.payload?.locale,
              )}
            </span>
          )
        },
        size: 110,
      },
      { id: 'visits', accessorKey: 'visits', header: 'Visits', maxSize: 70 },
      {
        id: 'activitiesCount',
        accessorKey: 'activitiesCount',
        header: 'Activities',
        maxSize: 90,
      },
    )
    if (flags && flags['teams-management']) {
      const teamsColumnDefinition: ColumnDef<Props['spaces'][number]> = {
        id: 'teams',
        header: 'Teams',
        cell: (ctx) => <TeamsColumn teams={ctx.row.original.teams} />,
        size: 130,
        enableSorting: true,
        enableHiding: true,
      }
      columns.push(teamsColumnDefinition)
    }
    columns.push({
      id: 'actions',
      size: 100,
      enableHiding: false,
      cell: (ctx) => {
        return (
          <div className={'flex justify-center items-center pr-6 gap-2'}>
            <Button2
              variant={'plain'}
              size={'small'}
              leadingIcon={'share-outline'}
              onClick={(e) => {
                e.stopPropagation()
                trackEvent({
                  event: 'spaces-copy_link-share_button',
                  eventProperties: {
                    indexPageName: 'Space List',
                  },
                })
                onCopyLinkToSpace(ctx.row.original.rootNodeId, ctx.row.original.authToken)
              }}
            />
            <div>
              <MenuDropdown
                Trigger={
                  <Button2 leadingIcon='ellipsis-vertical' size={'small'} variant={'plain'} />
                }
                placement={'bottom-start'}
                onTriggerClick={(isOpen) => {
                  trackEvent({
                    event: 'spaces-options_menu-toggle',
                    eventProperties: {
                      action: isOpen ? 'open' : 'close',
                      indexPageName: 'Space List',
                      spaceId: ctx.row.original?.id || 'unknownSpaceId',
                    },
                  })
                }}
              >
                <MenuDropdownItem
                  label='Open'
                  onClick={() => {
                    location.pathname = `/spaces/${ctx.row.original.rootNodeId}`
                  }}
                  LeftIcon={'log-in-outline'}
                  variant='default'
                ></MenuDropdownItem>
                <MenuDropdownItem
                  onClick={() => {
                    trackEvent({
                      event: 'spaces-open-new_tab',
                      eventProperties: {
                        indexPageName: 'Space List',
                      },
                    })
                    const redirectTo = `${window.location.origin.concat(
                      `/spaces/${ctx.row.original.rootNodeId}`,
                    )}`
                    window.open(redirectTo, '_blank', 'noopener,noreferrer')
                  }}
                  label='Open in new tab'
                  LeftIcon={'open-outline'}
                  variant='default'
                ></MenuDropdownItem>
                <MenuDropdownItem
                  onClick={() => {
                    trackEvent({
                      event: 'spaces-copy_link-options_menu',
                      eventProperties: {
                        indexPageName: 'Space List',
                      },
                    })
                    onCopyLinkToSpace(ctx.row.original.rootNodeId, ctx.row.original.authToken)
                  }}
                  label='Copy space link'
                  LeftIcon={'c_link'}
                  variant='default'
                />
                <MenuDropdownSeparator />
                {flags && flags['teams-management'] && (
                  <MenuDropdownItem
                    label={'Edit teams'}
                    LeftIcon={'create-outline'}
                    onClick={() => {
                      trackEvent({
                        event: 'spaces-edit-teams',
                        eventProperties: {
                          spaceId: ctx.row.original.id,
                        },
                      })
                      setSpaceSelectedForTeamEditing(ctx.row.original)
                    }}
                  />
                )}
                {isAllowedToSaveAsTemplate && (
                  <MenuDropdownItem
                    label={'Save as template'}
                    LeftIcon={'grid-outline'}
                    onClick={() => {
                      trackEvent({
                        event: 'spaces-save_as_template-open_modal',
                        eventProperties: {
                          spaceId: ctx.row.original.id,
                        },
                      })
                      showCreateTemplateFromSpaceOverlay(ctx.row.original.rootNodeId)
                    }}
                  />
                )}
                {isFlagsFetched &&
                  flags &&
                  flags['link-space-to-hub-spot-space-list-action'] &&
                  hasHubSpotIntegration && <LinkToHubSpotTrigger space={ctx.row.original} />}
                {hasSalesforceIntegration && <LinkToSalesforceTrigger space={ctx.row.original} />}
                {isAllowedToChangeSpaceOwner(ctx.row.original) ? (
                  <MenuDropdownItem
                    label={ctx.row.original.ownerId ? 'Change space owner' : 'Set space owner'}
                    LeftIcon={'shuffle-outline'}
                    onClick={() => {
                      showChangeSpaceOwnerModal(
                        ctx.row.original.rootNodeId,
                        ctx.row.original.companyName,
                        // we know that it's not undefined here because of the call to isAllowedToChangeSpaceOwner
                        ctx.row.original.ownerId
                          ? {
                              id: ctx.row.original.ownerId,
                              fullName: ctx.row.original.ownerName,
                            }
                          : undefined,
                      )
                    }}
                  />
                ) : (
                  <DisabledMenuDropdownItem
                    label={'Change space owner'}
                    LeftIcon={'shuffle-outline'}
                  />
                )}
                <MenuDropdownSeparator />
                {isFlagsFetched &&
                  flags &&
                  flags.archiving &&
                  isAllowedToArchiveSpace(ctx.row.original) && (
                    <SpaceArchivingTrigger
                      spaceId={ctx.row.original.rootNodeId}
                      companyName={ctx.row.original.companyName}
                      isCurrentlyArchived={ctx.row.original.archived}
                      spaceUrlWithToken={getUrlWithToken(
                        ctx.row.original.id,
                        ctx.row.original.authToken,
                      )}
                    />
                  )}
                <MenuDropdownItem
                  variant={'danger'}
                  LeftIcon={'trash-outline'}
                  label={'Delete space'}
                  onClick={() => {
                    trackEvent({
                      event: 'spaces-delete-open_modal',
                      eventProperties: {
                        indexPageName: 'Space List',
                        spaceId: ctx.row.original?.id || 'unknownSpaceId',
                      },
                    })
                    selectSpaceForDeletion(ctx.row.original)
                    setShowDeleteSpaceModal(true)
                  }}
                />
              </MenuDropdown>
            </div>
          </div>
        )
      },
    })

    return columns
  }, [
    flags,
    getUrlWithToken,
    hasHubSpotIntegration,
    hasSalesforceIntegration,
    isAllowedToArchiveSpace,
    isAllowedToChangeSpaceOwner,
    isAllowedToSaveAsTemplate,
    isFlagsFetched,
    onCopyLinkToSpace,
    showChangeSpaceOwnerModal,
    showCreateTemplateFromSpaceOverlay,
    tenant?.payload?.locale,
    trackEvent,
  ])

  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
    storage.getJSONValue(STORAGE_COLUMN_VISIBILITY_KEY) ?? {
      ownerId: false,
      updatedAt: false,
      templateName: false,
      score: false,
      teams: false,
    },
  )

  useEffect(() => {
    resetPagination()
  }, [filters, sorting, resetPagination])

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  )

  const table = useReactTable({
    data: spaces ?? [],
    columns: columns as any, // TODO fix space list column types
    enableFilters: true,
    onSortingChange: (newSorting) => {
      trackEvent({
        event: 'spaces-sort',
        eventProperties: {
          indexPageName: 'Space List',
        },
      })

      setSorting(newSorting)
    },
    onColumnVisibilityChange: (state) => {
      if (typeof state === 'function') {
        storage.setJSONValue(STORAGE_COLUMN_VISIBILITY_KEY, state(columnVisibility))
        setColumnVisibility(state)
      }
    },

    onPaginationChange: (state) => {
      if (typeof state === 'function') {
        const newState = state(pagination)
        setPagination(newState)
      }
    },
    getCoreRowModel: getCoreRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    state: {
      sorting,
      columnVisibility,
      pagination,
    },
    manualSorting: true,
    manualPagination: true,
    pageCount: totalPages,
  })

  const handleResetFilters = useCallback(() => {
    table.setGlobalFilter('active')
    table.resetColumnFilters()
    resetFilters()
    const owner = getSubWithoutPrefix(auth)
    if (owner) {
      table.setColumnFilters([{ id: 'ownerId', value: [owner] }])
    } else {
      table.resetColumnFilters()
    }
  }, [auth, table, resetFilters])

  const shouldShowNoSpacesFound = useMemo(() => {
    return !isFetching && !isLoading && (!spaces || spaces.length === 0) && totalCount === 0
  }, [spaces, isLoading, isFetching, totalCount])

  if (isLoading && spaces?.length === 0) {
    return <VLoaderAnimationFullSizeCentered />
  }

  return (
    <div>
      <SpacesPageHeader isSpaceListEmpty={shouldShowNoSpacesFound} />

      {shouldShowNoSpacesFound ? (
        <>
          <NoSpacesFound hasTemplates={templates && templates.length > 0} />
        </>
      ) : (
        <>
          {/* Lines below handle spaceSelectedForDeletion being undefined for type safety; however,
      showDeleteSpaceModal is only true when spaceSelectedForDeletion is a space and therefore,
      in practice, modal is only shown to users when a spaceSelectedForDeletion is set. Modal
      must be present here the whole time for animations to work (behind the scenes, actual html
      elements are only added to the dom tree when ready to be animated in). */}
          <ConfirmDeleteModal
            isShown={showDeleteSpaceModal}
            onClose={() => setShowDeleteSpaceModal(false)}
            confirmationMessage={
              <p className={'text-sm text-grey-s5 text-center'}>
                You&apos;re about to delete the space{' '}
                <b className={'font-extrabold'}>{spaceSelectedForDeletion?.companyName}</b>. This is
                non-reversible. Do you want to proceed?
              </p>
            }
            onConfirm={async () => {
              if (!spaceSelectedForDeletion) {
                return
              }
              try {
                setShowWaitingForLoadAnimation(true)
                await deleteSpaceAsync(spaceSelectedForDeletion.rootNodeId)
                trackEvent({
                  event: 'spaces-delete-confirm',
                  eventProperties: {
                    indexPageName: 'Delete Space Modal',
                    spaceId: spaceSelectedForDeletion.id || 'unknownSpaceId',
                  },
                })
                success(`Space '${spaceSelectedForDeletion.companyName}' successfully deleted.`)
                setShowDeleteSpaceModal(false)
              } catch (err) {
                error('There was an error deleting the space. Please try again later.')
                setShowWaitingForLoadAnimation(false)
              }
            }}
            title='Delete space'
            confirmButtonText='Delete'
            showLoader={showWaitingForLoadAnimation}
            footer={
              !spaceSelectedForDeletion?.archived && (
                <p className={'text-sm text-grey-s5 text-center mt-3'}>
                  Please rather{' '}
                  <button
                    onClick={async () => {
                      if (!spaceSelectedForDeletion) {
                        return
                      }
                      try {
                        setShowWaitingForLoadAnimation(true)
                        await onArchiveClick(spaceSelectedForDeletion.rootNodeId)
                        setShowDeleteSpaceModal(false)
                      } catch (err) {
                        error('There was an error archiving the space. Please try again later.')
                        setShowWaitingForLoadAnimation(false)
                      }
                    }}
                    className={
                      'text-primary-s5 no-underline font-extrabold hover:text-primary-s6 border-none m-0 p-0 min-w-0'
                    }
                  >
                    archive
                  </button>{' '}
                  my space.
                </p>
              )
            }
            onExit={() => {
              setShowWaitingForLoadAnimation(false)
              // Set space selected for deletion to undefined after modal exited, so modal data does
              // not change as fade out animates.
              selectSpaceForDeletion(undefined)
            }}
          />
          <FilterContainer table={table as any} />
          {spaceSelectedForTeamEditing && (
            <EditSpaceTeamsDialog
              space={spaceSelectedForTeamEditing}
              onOpenChange={() => setSpaceSelectedForTeamEditing(null)}
            />
          )}

          <DataTable
            isLoading={isLoading || isFetching}
            table={table}
            className={'pb-1'}
            rowClickHandler={(row) => {
              trackEvent({
                event: 'spaces-open-current_tab',
                eventProperties: {
                  indexPageName: 'Space List',
                },
              })
              window.location.href = getUrlWithToken(row.original.rootNodeId)
            }}
            rowClassNames={(row) => {
              return row.original.archived ? 'opacity-60' : undefined
            }}
            paginationClickHandler={() =>
              trackEvent({
                event: 'spaces-change_page',
                eventProperties: {
                  indexPageName: 'Space List',
                },
              })
            }
            resetFiltersClickHandler={handleResetFilters}
            tableWrapperClassName={'rounded-lg border border-grey-s2'}
          />
        </>
      )}
    </div>
  )
}

export default SpacesList
