import { ButtonHTMLAttributes, forwardRef, ReactNode, createContext, useContext } from 'react'
import { cn } from '../../lib'
import { IonIcon } from '../../Icons/IonIcon'

type ButtonGroupSize = 'default' | 'small'

const ButtonGroupContext = createContext<ButtonGroupSize>('default')

export const ButtonGroup = ({
  children,
  className,
  size = 'default',
}: {
  children: ReactNode
  className?: string
  size?: ButtonGroupSize
}) => {
  return (
    <ButtonGroupContext.Provider value={size}>
      <div
        className={cn(
          'inline-flex items-center justify-center rounded-lg',
          size === 'default' && 'h-10',
          size === 'small' && 'h-6',
          className,
        )}
      >
        {children}
      </div>
    </ButtonGroupContext.Provider>
  )
}

export const ButtonGroupItem = forwardRef<
  HTMLButtonElement,
  ButtonHTMLAttributes<HTMLButtonElement> & {
    leadingIcon?: string
    isActive?: boolean
  }
>(({ className, children, leadingIcon, isActive, ...props }, ref) => {
  const size = useContext(ButtonGroupContext)

  return (
    <button
      className={cn(
        'inline-flex items-center justify-center whitespace-nowrap text-xs shadow-none font-semibold transition-all outline-none border-r-[1px] border-t-[1px] border-b-[1px] border-grey-s2 bg-white',
        'hover:bg-grey-s1 hover:text-grey-s6',
        'first:rounded-l-lg first:border-l-[1px]',
        'last:rounded-r-lg last:border-r-[1px]',
        'disabled:pointer-events-none disabled:opacity-50 hover:text-grey-s6',
        'text-grey-s5 gap-1',
        isActive && 'bg-grey-s1 text-grey-s6',
        size === 'default' && 'px-3 h-9',
        size === 'small' && 'px-2 h-6 text-xs',
        className,
      )}
      ref={ref}
      {...props}
    >
      {leadingIcon && (
        <IonIcon
          name={leadingIcon}
          className={cn(
            'text-grey-s5',
            size === 'default' && 'w-4 h-4',
            size === 'small' && 'w-3 h-3',
          )}
        />
      )}
      {children}
    </button>
  )
})

ButtonGroupItem.displayName = 'ButtonGroupItem'
