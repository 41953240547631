import VLCSlogo from './assets/valuecase-logo.svg?react'
import { useMemo, useState } from 'react'
import { Auth, createAuthReturnPath, useAuthState } from '../../../auth/auth'
import { useCurrentTenantQuery } from '../../tenant/hooks/useReadTenant'
import { useLDflags } from '../../../launchdarkly/Launchdarkly'
import {
  AvatarThumbnail,
  Button2,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuSeparator,
  DropdownMenuTopbarItem,
  DropdownMenuTrigger,
  IonIcon,
} from '@valuecase/ui-components'
import {
  generatePageNameForMixpanelBasedOnOurRoutes,
  useTrackEvent,
} from '@/mixpanel/useTrackEvent'
import { STORAGE_FILTERS_KEY } from '@/modules/spaces/hooks/useSpaceFiltering'
import { useCurrentLocation } from '../hooks/useCurrentLocation'
import { useHasPermission } from '@/auth/permissions'
import { useNavigate } from '@tanstack/react-router'
import { getCompanyInitials } from '@valuecase/common'

const TopbarUserAvatar = ({
  name,
  profilePictureUrl,
  roleName,
}: {
  name: string
  profilePictureUrl?: string
  roleName?: string
}) => {
  return (
    <div className='flex items-center gap-3'>
      {profilePictureUrl !== undefined ? (
        <AvatarThumbnail
          type={'image'}
          imageUrl={profilePictureUrl}
          size={'LARGE'}
          shape={'CIRCLE'}
        />
      ) : (
        <AvatarThumbnail
          type={'user'}
          variant={'BOLD'}
          shape={'CIRCLE'}
          size={'LARGE'}
          initials={getCompanyInitials(name)}
        />
      )}
      <div className='flex flex-col cursor-pointer'>
        <div className='font-semibold text-xs text-grey-s6'>{name}</div>
        <div className='font-normal text-xs text-grey-s5'>{roleName}</div>
      </div>
    </div>
  )
}

const Topbar = () => {
  const authState = useAuthState()
  const { flags } = useLDflags()
  const { tenant, isFetched, isReloading, isLoading } = useCurrentTenantQuery()

  const nav = useNavigate()
  const currentLocation = useCurrentLocation()
  const { trackEvent } = useTrackEvent()

  const hasTenantSettingsPermission = useHasPermission('TENANT_SETTINGS_MANAGE')

  const adminSettingsEnabled = useMemo(
    () => hasTenantSettingsPermission,
    [hasTenantSettingsPermission],
  )
  const inviteTeamEnabled = useMemo(
    () => hasTenantSettingsPermission,
    [hasTenantSettingsPermission],
  )
  const tenantsManagementEnabled = useHasPermission('ALL_TENANTS_ADMIN')
  const adminMenuEnabled = useMemo(
    () => adminSettingsEnabled || inviteTeamEnabled || tenantsManagementEnabled,
    [adminSettingsEnabled, inviteTeamEnabled, tenantsManagementEnabled],
  )

  const logo = useMemo(() => {
    if (tenant?.payload?.whitelabelTenantLogoUrl) {
      return <img src={tenant?.payload.whitelabelTenantLogoUrl} />
    }
    return <VLCSlogo />
  }, [tenant?.payload?.whitelabelTenantLogoUrl])

  const [dropdownMenuOpen, setDropdownMenuOpen] = useState(false)

  if (!authState.authorized || !isFetched || !tenant || isReloading || isLoading) {
    return <></>
  }

  return (
    <div className='min-h-[72px] h-[72px] max-h-[72px] flex items-center bg-white border-b border-grey-s2'>
      <button className='flex-none ml-8 focus:outline-none' onClick={() => nav({ to: '/spaces' })}>
        {logo}
      </button>
      <div className='flex-1 text-center'>
        <span className='font-bold text-xs uppercase text-grey-s3'>
          {tenant?.payload?.tenantName || ''}
        </span>
      </div>
      <div className='flex-none px-4'>
        <DropdownMenu open={dropdownMenuOpen} onOpenChange={setDropdownMenuOpen}>
          <DropdownMenuTrigger asChild>
            <div
              className='flex gap-3 items-center h-14 p-2 cursor-pointer hover:bg-grey-s1 rounded-lg transition-all'
              data-intercom-target='dashboard-topbar-user-menu'
            >
              <TopbarUserAvatar
                name={authState.name}
                profilePictureUrl={authState.profilePictureUrl}
                roleName={authState.role?.name}
              />
              <IonIcon name='chevron-down-outline' className='w-4 h-4 text-grey-s5 p-1' />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className='border-none rounded-xl shadow-lg p-3 flex flex-col gap-3'
            sideOffset={16}
            align='end'
          >
            <div className='bg-[#F4F6FF] rounded-lg p-4 w-[272px]'>
              <TopbarUserAvatar
                name={authState.name}
                profilePictureUrl={authState.profilePictureUrl}
                roleName={authState.role?.name}
              />
            </div>
            <div className='flex flex-col gap-1'>
              <DropdownMenuTopbarItem
                icon='c_edit'
                onClick={() => {
                  trackEvent({
                    event: 'topbar-visit_profile',
                    eventProperties: {
                      indexPageName: generatePageNameForMixpanelBasedOnOurRoutes(currentLocation),
                    },
                  })
                  nav({ to: '/mySettings/profileAccount' })
                }}
              >
                Edit your details
              </DropdownMenuTopbarItem>
              <DropdownMenuTopbarItem
                icon='git-commit-outline'
                onClick={() => {
                  nav({ to: '/mySettings/integrations' })
                }}
              >
                Connect your tools
              </DropdownMenuTopbarItem>
              <DropdownMenuTopbarItem
                icon='notifications-outline'
                onClick={() => {
                  nav({ to: '/mySettings/mailNotifications' })
                }}
              >
                Your notification preferences
              </DropdownMenuTopbarItem>
            </div>
            {adminMenuEnabled && (
              <>
                <DropdownMenuSeparator className='mx-2 my-0' />
                <div className='flex flex-col gap-1'>
                  {adminSettingsEnabled && (
                    <DropdownMenuTopbarItem
                      icon='settings-outline'
                      onClick={() => {
                        nav({ to: '/administration/companySettings' })
                      }}
                    >
                      Admin settings
                    </DropdownMenuTopbarItem>
                  )}
                  {tenantsManagementEnabled && (
                    <DropdownMenuTopbarItem
                      icon='beer-outline'
                      onClick={() => nav({ to: '/tenants-admin' })}
                    >
                      Tenants Admin
                    </DropdownMenuTopbarItem>
                  )}
                  {inviteTeamEnabled && (
                    <Button2
                      className='w-full'
                      leadingIcon='rocket'
                      onClick={() => {
                        nav({ to: '/administration/userManagement' })
                        setDropdownMenuOpen(false)
                      }}
                    >
                      Invite your Team
                    </Button2>
                  )}
                </div>
              </>
            )}
            <DropdownMenuSeparator className='mx-2 my-0' />
            <DropdownMenuTopbarItem
              icon='log-out-outline'
              onClick={() => {
                trackEvent({
                  event: 'topbar-logout',
                  eventProperties: {
                    indexPageName: generatePageNameForMixpanelBasedOnOurRoutes(currentLocation),
                  },
                })
                // Delete all saved space filters to have a fresh start when logging in again
                localStorage.removeItem(STORAGE_FILTERS_KEY)
                Auth.logout(createAuthReturnPath())
              }}
            >
              Logout
            </DropdownMenuTopbarItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  )
}

export default Topbar
