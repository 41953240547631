import { spacesApi } from '@/api/spacesApi/spacesAPIclient'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
  Button2,
  FilterDropdown,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  extractTitleFromQuillObject,
  Input2,
  Switch,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  UTCRangeCalendar,
  DateRangePickerProps,
  Skeleton,
  cn,
  EmptyState,
  EmptyStateAction,
  EmptyStateDescription,
  EmptyStateHeader,
  EmptyStateTitle,
} from '@valuecase/ui-components'
import { DateTime } from 'luxon'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useReadTemplates } from '../templates/hooks/useReadTemplates'
import { ActionPlanAnalyticsTableGroup } from './ActionPlanGroup'
import { useReadAvailableSpaceOwners } from '../spaces/hooks/useReadAvailableSpaceOwners'
import {
  CellDetailsConfig,
  TTemplateSummaryQueryFilter,
  useActionPlanAnalyticsQuery,
  useActionPlanTasksOverTimeQuery,
} from './action-plan-analytics.helpers'
import { AverageCompletionDaysChart } from './charts/AverageCompletionDaysChart'
import { TaskCompletionChart } from './TaskCompletionChart'
import { ActionPlanAnalyticsCellDialog } from './ActionPlanAnalyticsCellDialog'
import { ActionPlanAnalyticsTableHeader } from './ActionPlanAnalyticsTableHeader'
import { Label } from '@valuecase/ui-components/src/components/ui/label'
import { useLDflags } from '@/launchdarkly/Launchdarkly'
import { useTrackEvent } from '@/mixpanel/useTrackEvent'
import DudeWithAPlusSign from '../../assets/dude-with-a-plus-sign.svg?react'
import {
  ActionPlanAnalyticsGroupingLevel as GroupingLevel,
  ActionPlanAnalyticsTimeRangePeriod as TimeRange,
  isTaskAnalyticsSearch,
  TActionPlanAnalyticsResponse,
  TActionPlanAnalyticsTimeRangePeriod,
  TTaskAnalyticsSearch,
  TTasksCompletedOverTimeGroupBy as GroupByPeriod,
  TTemplateSummarySpacesGroupType,
  ActionPlanAnalyticsGroupingPeriod as GroupingPeriod,
  TActionPlanAnalyticsTimeRange,
} from '@valuecase/common'
import { useHasPermission } from '@/auth/permissions'
import { useNavigate, useSearch } from '@tanstack/react-router'
import { taskAnalyticsRoute } from '../navigation/Router'

const analyticsTableGridClass =
  'grid grid-cols-[1fr_max-content_max-content_max-content_max-content_max-content_max-content]'

const defaultGroupingLevel = GroupingLevel.Template
const defaultIncludeArchivedSpaces = true
const defaultIncludeArchivedTemplates = false

const ActionPlanAnalytics = () => {
  const navigate = useNavigate()
  const searchParams = useSearch<typeof taskAnalyticsRoute>({ strict: false })
  const hasSpacesAllAdministrationPermission = useHasPermission('SPACES_ALL_ADMINISTRATION')
  const search = searchParams.search || ''
  const timeRangePeriod = useMemo(
    () => searchParams.timeRangePeriod || TimeRange.LastThreeMonths,
    [searchParams.timeRangePeriod],
  )
  const groupBy = searchParams.groupBy || GroupingPeriod.Month
  const includeArchivedSpaces = searchParams.includeArchivedSpaces ?? defaultIncludeArchivedSpaces
  const includeArchivedTemplates =
    searchParams.includeArchivedTemplates ?? defaultIncludeArchivedTemplates
  const ownerIds = useMemo(
    () => (searchParams.ownerIds ? new Set(searchParams.ownerIds) : undefined),
    [searchParams.ownerIds],
  )
  const templateIds = useMemo(
    () => (searchParams.templateIds ? new Set(searchParams.templateIds) : undefined),
    [searchParams.templateIds],
  )
  const actionPlanIds = useMemo(
    () => (searchParams.actionPlanIds ? new Set(searchParams.actionPlanIds) : undefined),
    [searchParams.actionPlanIds],
  )
  const milestoneIds = useMemo(
    () => (searchParams.milestoneIds ? new Set(searchParams.milestoneIds) : undefined),
    [searchParams.milestoneIds],
  )
  const groupingLevel = searchParams.groupingLevel || defaultGroupingLevel

  const setSearch = useCallback(
    (value: string) =>
      navigate({
        search: (prev) =>
          !isTaskAnalyticsSearch(prev)
            ? { search: value || undefined }
            : { ...prev, search: value || undefined },
      }),
    [navigate],
  )

  const setGroupingLevel = useCallback(
    (value: typeof groupingLevel) =>
      navigate({
        search: (prev) =>
          !isTaskAnalyticsSearch(prev)
            ? value === defaultGroupingLevel
              ? // If not a valid search object and the value is the default, return empty search
                //  object
                {}
              : // If not a valid search object and the value is not the default, create a new
                // search object with the grouping level
                { groupingLevel: value }
            : value === defaultGroupingLevel
              ? // If a valid search object and the value is the default, remove the grouping level
                { ...prev, groupingLevel: undefined }
              : // If a valid search object and the value is not the default, update the grouping
                // level
                { ...prev, groupingLevel: value },
      }),
    [navigate],
  )

  const toggleListItemSelection = useCallback(
    (
      id: string,
      key: keyof Pick<
        TTaskAnalyticsSearch,
        'actionPlanIds' | 'milestoneIds' | 'ownerIds' | 'templateIds'
      >,
    ) =>
      navigate({
        search: (prev) => {
          // If not a valid search object, create a new search object with the key filtering for the
          // provided id.
          if (!isTaskAnalyticsSearch(prev)) {
            return { [key]: [id] }
          }
          // If the key is not present in the search object, add it and filter for the provided id.
          const isArray = Array.isArray(prev[key])
          if (!isArray) {
            return { ...prev, [key]: [id] }
          }
          const hasItem = prev[key]?.includes(id)
          if (hasItem) {
            // If the item is in the list, remove it
            const newValue = prev[key]?.filter((x) => x !== id)
            if (!newValue || newValue.length === 0) {
              // If it is the last item to be removed, remove the key from the search object
              return { ...prev, [key]: undefined }
            } else {
              // Otherwise, return the new list without the item
              return { ...prev, [key]: newValue }
            }
          } else {
            // If the item is not in the list, add it
            return { ...prev, [key]: [...(prev[key] ?? []), id] }
          }
        },
      }),
    [navigate],
  )

  const toggleTemplateSelection = useCallback(
    (id: string) => toggleListItemSelection(id, 'templateIds'),
    [toggleListItemSelection],
  )

  const setTemplateIds = useCallback(
    (ids: Set<string> | undefined) =>
      navigate({
        search: (prev) =>
          !isTaskAnalyticsSearch(prev)
            ? { templateIds: ids ? Array.from(ids) : undefined }
            : { ...prev, templateIds: ids ? Array.from(ids) : undefined },
      }),
    [navigate],
  )

  const toggleActionPlanSelection = useCallback(
    (id: string) => toggleListItemSelection(id, 'actionPlanIds'),
    [toggleListItemSelection],
  )

  const setActionPlanIds = useCallback(
    (ids: Set<string> | undefined) =>
      navigate({
        search: (prev) =>
          !isTaskAnalyticsSearch(prev)
            ? { actionPlanIds: ids ? Array.from(ids) : undefined }
            : { ...prev, actionPlanIds: ids ? Array.from(ids) : undefined },
      }),
    [navigate],
  )

  const toggleOwnerSelection = useCallback(
    (id: string) => toggleListItemSelection(id, 'ownerIds'),
    [toggleListItemSelection],
  )

  const setOwnerIds = useCallback(
    (ids: Set<string> | undefined) =>
      navigate({
        search: (prev) =>
          !isTaskAnalyticsSearch(prev)
            ? { ownerIds: ids ? Array.from(ids) : undefined }
            : { ...prev, ownerIds: ids ? Array.from(ids) : undefined },
      }),
    [navigate],
  )

  const toggleMilestoneSelection = useCallback(
    (id: string) => toggleListItemSelection(id, 'milestoneIds'),
    [toggleListItemSelection],
  )

  const setMilestoneIds = useCallback(
    (ids: Set<string> | undefined) =>
      navigate({
        search: (prev) =>
          !isTaskAnalyticsSearch(prev)
            ? { milestoneIds: ids ? Array.from(ids) : undefined }
            : { ...prev, milestoneIds: ids ? Array.from(ids) : undefined },
      }),
    [navigate],
  )

  const setIncludeArchivedSpaces = useCallback(
    (value: boolean) =>
      navigate({
        search: (prev) =>
          // If value is set to true, remove the includeArchivedSpaces key from the search object as
          // it is the default value
          !isTaskAnalyticsSearch(prev)
            ? { includeArchivedSpaces: value ? undefined : false }
            : { ...prev, includeArchivedSpaces: value ? undefined : false },
      }),
    [navigate],
  )

  const setIncludeArchivedTemplates = useCallback(
    (value: boolean) =>
      navigate({
        search: (prev) =>
          // If value is set to false, remove the includeArchivedTemplates key from the search
          // object as it is the default value
          !isTaskAnalyticsSearch(prev)
            ? { includeArchivedTemplates: !value ? undefined : value }
            : { ...prev, includeArchivedTemplates: !value ? undefined : value },
      }),
    [navigate],
  )

  const setGroupBy = useCallback(
    (value: GroupByPeriod) =>
      navigate({
        search: (prev) =>
          // If value is set to 'month', remove the groupBy key from the search object as it is the
          // default value
          !isTaskAnalyticsSearch(prev)
            ? { groupBy: value === GroupingPeriod.Month ? undefined : value }
            : { ...prev, groupBy: value === GroupingPeriod.Month ? undefined : value },
      }),
    [navigate],
  )

  const setTimeRange = useCallback<
    <T extends TActionPlanAnalyticsTimeRangePeriod>(
      period: T,
      value: T extends 'custom' ? TActionPlanAnalyticsTimeRange : undefined,
    ) => ReturnType<ReturnType<typeof useNavigate>>
  >(
    (period, value) =>
      navigate({
        search: (prev) => {
          const newTimeRangeParams = {
            timeRangePeriod: period === TimeRange.LastThreeMonths ? undefined : period,
            spaceCreatedAfter:
              period === TimeRange.Custom ? value?.spaceCreatedAfter.toISODate() : undefined,
            spaceCreatedBefore:
              period === TimeRange.Custom ? value?.spaceCreatedBefore?.toISODate() : undefined,
          }
          return !isTaskAnalyticsSearch(prev)
            ? newTimeRangeParams
            : { ...prev, ...newTimeRangeParams }
        },
      }),
    [navigate],
  )

  // Resets all filters while retaining the grouping level
  const resetFilters = useCallback(
    () =>
      navigate({
        search: (prev) =>
          !isTaskAnalyticsSearch(prev) ? {} : { groupingLevel: prev.groupingLevel },
      }),
    [navigate],
  )

  const [hasAnalyticsData, setHasAnalyticsData] = useState<boolean | undefined>(undefined)
  const [dateSelectionOpen, setDateSelectionOpen] = useState(false)
  const flagsQuery = useLDflags()
  const [filtersOpen, setFiltersOpen] = useState(false)
  const [isCellDetailsDialogOpen, setIsCellDetailsDialogOpen] = useState(false)
  const [cellDetailsConfig, setCellDetailsConfig] = useState<CellDetailsConfig | undefined>(
    undefined,
  )

  const spaceCreatedAfter = useMemo(() => {
    if (searchParams.spaceCreatedAfter) {
      return DateTime.fromISO(searchParams.spaceCreatedAfter).toJSDate()
    }
    switch (timeRangePeriod) {
      case TimeRange.LastWeek:
        return DateTime.now().startOf('day').minus({ days: 6 }).startOf('day').toJSDate()
      case TimeRange.LastTwoWeeks:
        return DateTime.now().startOf('day').minus({ days: 13 }).startOf('day').toJSDate()
      case TimeRange.ThisMonth:
        return DateTime.now().startOf('month').toJSDate()
      case TimeRange.LastMonth:
        return DateTime.now().startOf('month').minus({ months: 1 }).startOf('day').toJSDate()
      case TimeRange.LastThreeMonths:
        return DateTime.now().minus({ months: 3 }).startOf('day').toJSDate()
      case TimeRange.ThisQuarter:
        return DateTime.now().startOf('quarter').toJSDate()
      case TimeRange.LastQuarter:
        return DateTime.now().startOf('quarter').minus({ quarters: 1 }).startOf('day').toJSDate()
      case TimeRange.LastTwelveMonths:
        return DateTime.now().minus({ months: 12 }).startOf('day').toJSDate()
      default:
        return DateTime.now().minus({ months: 3 }).startOf('day').toJSDate()
    }
  }, [searchParams.spaceCreatedAfter, timeRangePeriod])

  const spaceCreatedBefore = useMemo(() => {
    if (searchParams.spaceCreatedBefore) {
      return DateTime.fromISO(searchParams.spaceCreatedBefore).toJSDate()
    }
    switch (timeRangePeriod) {
      // These dates imply "up until now" - i.e. no end date.
      case TimeRange.LastWeek:
      case TimeRange.LastTwoWeeks:
      case TimeRange.ThisMonth:
      case TimeRange.LastThreeMonths:
      case TimeRange.ThisQuarter:
      case TimeRange.LastTwelveMonths:
        // Ends at midnight of tomorrow; i.e. includes all of today, but not tomorrow.
        return DateTime.now().plus({ day: 1 }).startOf('day').toJSDate()
      case TimeRange.LastMonth:
        return DateTime.now().startOf('month').toJSDate()
      case TimeRange.LastQuarter:
        return DateTime.now().startOf('quarter').toJSDate()
      default:
        return DateTime.now().plus({ days: 1 }).startOf('day').toJSDate()
    }
  }, [searchParams.spaceCreatedBefore, timeRangePeriod])

  const [selectedTimeRange, setSelectedTimeRange] = useState<
    Parameters<DateRangePickerProps['onChange']>[0]
  >({
    startDate: DateTime.fromJSDate(spaceCreatedAfter)
      .setZone('UTC', { keepLocalTime: true })
      .toJSDate(),
    endDate: DateTime.fromJSDate(spaceCreatedBefore)
      .setZone('UTC', { keepLocalTime: true })
      .minus({ days: 1 })
      .startOf('day')
      .toJSDate(),
  })

  // While the date selection dialog is closed, keep the selected time range in sync with the
  // spaceCreatedAfter and spaceCreatedBefore values
  useEffect(() => {
    if (dateSelectionOpen) {
      return
    }
    const utcStartDate = DateTime.fromJSDate(spaceCreatedAfter)
      .setZone('UTC', { keepLocalTime: true })
      .toJSDate()
    const utcEndDate = DateTime.fromJSDate(spaceCreatedBefore)
      .setZone('UTC', { keepLocalTime: true })
      .minus({ days: 1 })
      .startOf('day')
      .toJSDate()
    const shouldUpdateStartDate = utcStartDate.valueOf() !== selectedTimeRange.startDate?.valueOf()
    const shouldUpdateEndDate = utcEndDate.valueOf() !== selectedTimeRange.endDate?.valueOf()
    if (!shouldUpdateStartDate && !shouldUpdateEndDate) {
      return
    }
    const newTimeRange = {
      startDate: shouldUpdateStartDate ? utcStartDate : selectedTimeRange.startDate,
      endDate: shouldUpdateEndDate ? utcEndDate : selectedTimeRange.endDate,
    }
    setSelectedTimeRange(newTimeRange)
  }, [
    dateSelectionOpen,
    selectedTimeRange,
    selectedTimeRange.startDate,
    spaceCreatedAfter,
    spaceCreatedBefore,
  ])

  const { trackEvent } = useTrackEvent()

  useEffect(() => {
    trackEvent({
      event: 'dashboard-visit',
      eventProperties: {
        indexPageName: 'Action Plan Analytics page',
      },
    })
  }, [trackEvent])

  /**
   * Variables set in connection with querying the backend
   */
  // Owner data from the backend
  const { availableSpaceOwners } = useReadAvailableSpaceOwners()
  const sortedOwners = useMemo(() => {
    if (!availableSpaceOwners || availableSpaceOwners.length === 0) {
      return []
    }
    return [...availableSpaceOwners]
      .map((owner) => ({
        id: owner.id,
        title: `${owner.firstName} ${owner.lastName}`,
        email: owner.email,
      }))
      .sort((a, b) => a.title.localeCompare(b.title))
  }, [availableSpaceOwners])

  // Template data from the backend
  const { templates: templatesResponse } = useReadTemplates()
  const templates = useMemo(() => templatesResponse || [], [templatesResponse])

  // Action plan data from the backend
  const actionPlanTemplatesQuery = useQuery({
    queryKey: ['actionPlanTemplates', includeArchivedTemplates],
    queryFn: async () => {
      const res = await spacesApi.get(
        `/action-plans/templates?includeArchived=${includeArchivedTemplates}`,
      )
      const data = res.data as { items: { id: string; title: string }[] }
      if (data && typeof data === 'object' && Array.isArray(data.items)) {
        data.items = data.items.map((item) => ({
          ...item,
          title: extractTitleFromQuillObject(item.title) || '',
        }))
      }
      return data
    },
    staleTime: 0,
  })
  const actionPlans = useMemo(() => {
    return actionPlanTemplatesQuery.data?.items || []
  }, [actionPlanTemplatesQuery])

  // Milestone data from the backend (if enabled)
  const milestonesFilterEnabled = useMemo(() => {
    return !!templateIds || !!actionPlanIds
  }, [templateIds, actionPlanIds])
  const milestoneTemplatesQuery = useQuery({
    queryKey: [
      'milestoneTemplates',
      templateIds && Array.from(templateIds).join(','),
      actionPlanIds && Array.from(actionPlanIds).join(','),
      includeArchivedTemplates,
    ],
    queryFn: async () => {
      if (!templateIds && !actionPlanIds) {
        return { items: [] }
      }
      const params = new URLSearchParams()
      if (templateIds) {
        params.set('templateIds', Array.from(templateIds).join(','))
      }
      if (actionPlanIds) {
        params.set('actionPlanNodeIds', Array.from(actionPlanIds).join(','))
      }
      if (includeArchivedTemplates) {
        params.set('includeArchived', includeArchivedTemplates.toString())
      }
      const res = await spacesApi.get(`/action-plan-milestones/templates?${params.toString()}`)
      const data = res.data as { items: { id: string; title: string }[] }
      if (data?.items) {
        data.items = data.items.map((item) => ({
          ...item,
          title: extractTitleFromQuillObject(item.title) || '',
        }))
      }
      return data
    },
    enabled: milestonesFilterEnabled,
    staleTime: 0,
  })
  const milestones = useMemo(() => {
    return milestoneTemplatesQuery.data?.items || []
  }, [milestoneTemplatesQuery.data])

  // Task analytics data from the backend
  const filter: TTemplateSummaryQueryFilter = useMemo(() => {
    const before = spaceCreatedBefore && DateTime.fromJSDate(spaceCreatedBefore)
    // If the spaceCreatedBefore date is in the future, consider it undefined
    const effectiveSpaceCreatedBefore =
      before && before >= DateTime.now() ? undefined : spaceCreatedBefore

    return {
      templateIds,
      actionPlanIds,
      milestoneIds,
      search,
      groupingLevel,
      ownerIds,
      includeArchivedTemplates,
      includeArchivedSpaces,
      spaceCreatedAfter,
      spaceCreatedBefore: effectiveSpaceCreatedBefore,
    }
  }, [
    templateIds,
    actionPlanIds,
    milestoneIds,
    search,
    groupingLevel,
    ownerIds,
    includeArchivedTemplates,
    includeArchivedSpaces,
    spaceCreatedAfter,
    spaceCreatedBefore,
  ])

  const taskAnalyticsQuery = useActionPlanAnalyticsQuery({ filter })
  const taskAnalytics = useMemo(
    () =>
      taskAnalyticsQuery.data ||
      ({ items: [], meta: { lastRefreshed: undefined } } as TActionPlanAnalyticsResponse),
    [taskAnalyticsQuery.data],
  )

  // Tasks over time data from the backend
  const actionPlanTasksOverTimeQuery = useActionPlanTasksOverTimeQuery({
    filter,
    groupBy,
    enabled: groupingLevel === GroupingLevel.Template,
  })
  const totalTasksCompleted = useMemo(() => {
    if (!actionPlanTasksOverTimeQuery) {
      return 0
    }
    return actionPlanTasksOverTimeQuery?.data?.reduce((acc, curr) => acc + curr.count, 0) ?? 0
  }, [actionPlanTasksOverTimeQuery])

  // Request to trigger a refresh of the analytics data via a mutation
  const refreshMutation = useMutation({
    mutationFn: async () => {
      return spacesApi.post('/action-plan-analytics/refresh')
    },
  })

  const timeRangeButtonLabelText = useMemo(() => {
    switch (timeRangePeriod) {
      case TimeRange.LastWeek:
        return 'Spaces created in the last week'
      case TimeRange.LastTwoWeeks:
        return 'Spaces created in the last 2 weeks'
      case TimeRange.ThisMonth:
        return 'Spaces created this month'
      case TimeRange.LastMonth:
        return 'Spaces created last month'
      case TimeRange.LastThreeMonths:
        return 'Spaces created in the last 3 months'
      case TimeRange.ThisQuarter:
        return 'Spaces created this quarter'
      case TimeRange.LastQuarter:
        return 'Spaces created last quarter'
      case TimeRange.LastTwelveMonths:
        return 'Spaces created in the last 12 months'
      default:
        if (!spaceCreatedBefore && !spaceCreatedAfter) {
          return 'All time'
        }
        if (!spaceCreatedBefore) {
          return `Spaces created after ${DateTime.fromJSDate(spaceCreatedAfter).toLocaleString()}`
        }
        if (!spaceCreatedAfter) {
          return `Spaces created before ${DateTime.fromJSDate(spaceCreatedBefore).toLocaleString()}`
        }
        if (
          DateTime.fromJSDate(spaceCreatedAfter).toISODate() ===
          DateTime.fromJSDate(spaceCreatedBefore).minus({ days: 1 }).toISODate()
        ) {
          return `Spaces created on ${DateTime.fromJSDate(spaceCreatedAfter).toLocaleString()}`
        }
        return `Spaces created between ${DateTime.fromJSDate(
          spaceCreatedAfter,
        ).toLocaleString()} and ${DateTime.fromJSDate(spaceCreatedBefore).minus({ days: 1 }).toLocaleString()}`
    }
  }, [spaceCreatedAfter, spaceCreatedBefore, timeRangePeriod])

  const handleCellClick = useCallback(
    ({
      originNodeId,
      groupType,
    }: {
      originNodeId: string
      groupType: TTemplateSummarySpacesGroupType
    }) => {
      if (!originNodeId || !groupType) {
        return
      }
      switch (groupType) {
        case 'quickest':
          setCellDetailsConfig({
            title: 'Quickest Quartile',
            description: `The quartile of spaces that took the shortest time to complete this ${groupingLevel}`,
            originNodeId,
            groupType,
          })
          break
        case 'slowest':
          setCellDetailsConfig({
            title: 'Slowest Quartile',
            description: `The quartile of spaces that took the longest time to complete this ${groupingLevel}`,
            originNodeId,
            groupType,
          })
          break
        case 'days to completion':
          setCellDetailsConfig({
            title: 'Days to completion',
            description: `The number of days it took to complete this ${groupingLevel}`,
            originNodeId,
            groupType,
          })
          break
        case 'completed':
          setCellDetailsConfig({
            title: 'Completed',
            description: `Spaces which have completed this ${groupingLevel}`,
            originNodeId,
            groupType,
          })
          break
        case 'space count':
          setCellDetailsConfig({
            title: 'Spaces',
            description: `Spaces containing this ${groupingLevel}`,
            originNodeId,
            groupType,
          })
          break
      }
      setIsCellDetailsDialogOpen(true)
    },
    [groupingLevel, setCellDetailsConfig, setIsCellDetailsDialogOpen],
  )

  const spacesCompletedCount = useMemo(
    () => taskAnalytics?.items?.reduce((acc, curr) => acc + (curr?.completedCount || 0), 0),
    [taskAnalytics],
  )
  const spaceCount = useMemo(
    () => taskAnalytics?.items?.reduce((acc, curr) => acc + (curr?.spaceCount || 0), 0) ?? 0,
    [taskAnalytics],
  )
  const totalAvgCompletionDays = useMemo(() => {
    if (!taskAnalytics || !taskAnalytics.items) {
      return 0
    }
    const filteredData = taskAnalytics.items.filter(
      (item) => typeof item.avgCompletionDays === 'number',
    )
    const sum = filteredData.reduce((acc, curr) => acc + (curr.avgCompletionDays || 0), 0)
    return filteredData.length > 0 ? Math.ceil(sum / filteredData.length) : 0
  }, [taskAnalytics])

  const showNoMatchesState = useMemo(() => {
    return (
      taskAnalyticsQuery.isSuccess && hasAnalyticsData === true && taskAnalytics.items.length === 0
    )
  }, [hasAnalyticsData, taskAnalytics.items.length, taskAnalyticsQuery.isSuccess])

  const cellDetailsFilter = useMemo(() => {
    if (!cellDetailsConfig) {
      return undefined
    }
    return {
      ...filter,
      originNodeId: cellDetailsConfig.originNodeId,
      groupType: cellDetailsConfig.groupType,
    }
  }, [cellDetailsConfig, filter])

  const showResetButton = useMemo(() => Object.keys(searchParams).length > 0, [searchParams])

  // Only run the default query if the query that was run initially did not return any data. I.e.
  // They opened a specific filter, and there was no data for that filter, but we still want to know
  // if they reset the filters if something will show up. Only if the query on load and the default
  // query both return no data, is the empty state shown.
  const shouldCheckDefaultQueryForData = useMemo(
    () =>
      typeof hasAnalyticsData === 'undefined' &&
      taskAnalyticsQuery.isSuccess &&
      taskAnalytics.items.length === 0,
    [hasAnalyticsData, taskAnalyticsQuery.isSuccess, taskAnalytics.items.length],
  )

  const taskAnalyticsDefaultQuery = useActionPlanAnalyticsQuery({
    filter: {
      groupingLevel: defaultGroupingLevel,
      includeArchivedSpaces: defaultIncludeArchivedSpaces,
      includeArchivedTemplates: defaultIncludeArchivedTemplates,
      templateIds: undefined,
      actionPlanIds: undefined,
      milestoneIds: undefined,
      ownerIds: undefined,
      spaceCreatedAfter: DateTime.now().minus({ months: 3 }).startOf('day').toJSDate(),
      spaceCreatedBefore: undefined,
    },
    enabled: shouldCheckDefaultQueryForData,
  })

  // This use effect is responsible for determining whether there is at least one row of analytics
  // data available. If there is, the empty state is not shown. If there is not, the empty state is
  // shown.
  useEffect(() => {
    // If analytics data was found at least once, retain that value.
    if (hasAnalyticsData) {
      return
    }
    // While loading the initial data, keep the value as undefined.
    if (!taskAnalyticsQuery.isSuccess) {
      return
    }
    // If analytics data was found with the initial query, save the fact that there is analytics
    // data available.
    if (taskAnalytics.items.length > 0) {
      setHasAnalyticsData(true)
      return
    }
    if (!taskAnalyticsDefaultQuery.isSuccess) {
      return
    }
    const value = taskAnalyticsDefaultQuery.data.items.length > 0
    setHasAnalyticsData(value)
  }, [
    hasAnalyticsData,
    taskAnalytics.items.length,
    taskAnalyticsDefaultQuery.data?.items.length,
    taskAnalyticsDefaultQuery.isSuccess,
    taskAnalyticsQuery.isSuccess,
  ])

  const showEmptyState = useMemo(() => {
    return taskAnalyticsQuery.isSuccess && hasAnalyticsData === false
  }, [hasAnalyticsData, taskAnalyticsQuery.isSuccess])

  return (
    <>
      {showEmptyState && (
        <div className='pt-11 w-full h-full flex flex-col flex-grow'>
          <h1 className={'text-2xl font-bold mb-4'}>Analytics</h1>
          <div className='flex-grow flex w-full items-center justify-center'>
            <EmptyState className='p-4 gap-4'>
              <DudeWithAPlusSign className='w-32 h-32' />
              <EmptyStateHeader className='max-w-[312px]'>
                <EmptyStateTitle>Template Your First Action Plan!</EmptyStateTitle>
                <EmptyStateDescription>
                  And build understanding on how your action plan strategy is working
                </EmptyStateDescription>
              </EmptyStateHeader>
              <EmptyStateAction
                className='py-0 h-auto'
                variant={'link'}
                onClick={() =>
                  window.open('https://help.valuecase.de/en/articles/77752-action-plan-block')
                }
                trailingIcon='arrow-forward'
              >
                Learn more
              </EmptyStateAction>
            </EmptyState>
          </div>
        </div>
      )}
      {!showEmptyState && (
        <div className='py-11 w-full flex flex-col'>
          {/* // When clicking on, for example, the lower quartile cell for a task, this dialog
       lists the bottom 25% of spaces that took the longest to complete the task */}
          {!!cellDetailsFilter && (
            <ActionPlanAnalyticsCellDialog
              open={isCellDetailsDialogOpen}
              onOpenChange={setIsCellDetailsDialogOpen}
              cellDetailsConfig={cellDetailsConfig}
              filters={cellDetailsFilter}
            />
          )}
          <div className={'flex flex-col w-full flex-grow'}>
            <h1 className={'text-2xl font-bold'}>Analytics</h1>
            <Tabs
              value={groupingLevel}
              onValueChange={(value) => {
                if (search) {
                  setSearch('')
                }
                setGroupingLevel(value as typeof groupingLevel)
                trackEvent({
                  event: 'analytics-action_plan-tab',
                  eventProperties: {
                    actionPlanTab:
                      value === GroupingLevel.Template
                        ? 'Summary'
                        : value.charAt(0).toUpperCase() + value.slice(1),
                  },
                })
              }}
            >
              <div className='flex flex-row justify-between mt-6 mb-4 border-b border-grey-s2'>
                <TabsList className='h-7 gap-3 pl-0'>
                  <TabsTrigger
                    className='pt-0 pb-2 px-1 text-grey-s5 font-semibold'
                    value={GroupingLevel.Template}
                  >
                    Summary
                  </TabsTrigger>
                  <TabsTrigger
                    className='pt-0 pb-2 px-1 text-grey-s5 font-semibold'
                    value={GroupingLevel.ActionPlan}
                  >
                    Action Plan
                  </TabsTrigger>
                  <TabsTrigger
                    className='pt-0 pb-2 px-1 text-grey-s5 font-semibold'
                    value={GroupingLevel.Milestone}
                  >
                    Milestones
                  </TabsTrigger>
                  <TabsTrigger
                    className='pt-0 pb-2 px-1 text-grey-s5 font-semibold'
                    value={GroupingLevel.Task}
                  >
                    Tasks
                  </TabsTrigger>
                </TabsList>
                <DropdownMenu open={dateSelectionOpen} onOpenChange={setDateSelectionOpen}>
                  <DropdownMenuTrigger asChild>
                    <Button2 variant={'ghost'} size={'small'} trailingIcon='chevron-down'>
                      {timeRangeButtonLabelText}
                    </Button2>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align='end' className='pr-0'>
                    <div>
                      <div className='flex flex-row justify-end items-center pt-2 pb-4 pr-4'>
                        <Label className='ml-auto mr-2 text-xs font-semibold text-grey-s4'>
                          Start date
                        </Label>
                        <Input2
                          className='w-auto mr-8'
                          inputClassName='appearance-none hide-calendar-icon'
                          id='spaces-created-after-date'
                          type='date'
                          value={DateTime.fromJSDate(
                            selectedTimeRange.startDate || new Date(),
                          ).toFormat('yyyy-MM-dd')}
                          onChange={(e) => {
                            if (!e.target.value) {
                              return
                            }
                            setSelectedTimeRange({
                              ...selectedTimeRange,
                              startDate: DateTime.fromISO(e.target.value).toJSDate(),
                            })
                          }}
                        />
                        <Label className='mr-2 text-xs font-semibold text-grey-s4'>End date</Label>
                        <Input2
                          className='w-auto'
                          inputClassName='appearance-none hide-calendar-icon'
                          id='spaces-created-before-date'
                          type='date'
                          value={DateTime.fromJSDate(
                            selectedTimeRange.endDate || new Date(),
                          ).toFormat('yyyy-MM-dd')}
                          onChange={(e) => {
                            if (!e.target.value) {
                              return
                            }
                            setSelectedTimeRange({
                              ...selectedTimeRange,
                              endDate: DateTime.fromISO(e.target.value).toJSDate(),
                            })
                          }}
                        />
                      </div>
                      <div className='flex flew-row'>
                        <div className='pr-4 pt-4'>
                          <DropdownMenuRadioGroup
                            value={timeRangePeriod}
                            onValueChange={(value) =>
                              setTimeRange(value as TActionPlanAnalyticsTimeRangePeriod, undefined)
                            }
                          >
                            <DropdownMenuRadioItem value={TimeRange.LastWeek}>
                              Last week
                            </DropdownMenuRadioItem>
                            <DropdownMenuRadioItem value={TimeRange.LastTwoWeeks}>
                              Last 2 weeks
                            </DropdownMenuRadioItem>
                            <DropdownMenuRadioItem value={TimeRange.ThisMonth}>
                              This month
                            </DropdownMenuRadioItem>
                            <DropdownMenuRadioItem value={TimeRange.LastMonth}>
                              Last month
                            </DropdownMenuRadioItem>
                            <DropdownMenuRadioItem value={TimeRange.LastThreeMonths}>
                              Last 3 months
                            </DropdownMenuRadioItem>
                            <DropdownMenuRadioItem value={TimeRange.ThisQuarter}>
                              This quarter
                            </DropdownMenuRadioItem>
                            <DropdownMenuRadioItem value={TimeRange.LastQuarter}>
                              Last quarter
                            </DropdownMenuRadioItem>
                            <DropdownMenuRadioItem value={TimeRange.LastTwelveMonths}>
                              Last 12 months
                            </DropdownMenuRadioItem>
                          </DropdownMenuRadioGroup>
                        </div>
                        <div>
                          <div className='p-4 bg-background'>
                            <div className='bg-white rounded-lg p-4 overflow-hidden'>
                              <UTCRangeCalendar
                                startDate={selectedTimeRange.startDate}
                                endDate={selectedTimeRange.endDate}
                                defaultMonth={spaceCreatedAfter}
                                numberOfMonths={2}
                                onChange={(value) => setSelectedTimeRange(value)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='flex flex-row justify-end p-4 pb-2'>
                        <Button2
                          size={'small'}
                          leadingIcon='checkmark'
                          disabled={!selectedTimeRange.startDate || !selectedTimeRange.endDate}
                          onClick={() => {
                            if (!selectedTimeRange.startDate || !selectedTimeRange.endDate) {
                              return
                            }
                            // Since UTCRangeCalendar returns start and end dates in UTC but task
                            // analytics filters work based on local time, convert the UTC dates to
                            // local time but preserve the time of day - i.e. midnight UTC should be
                            // midnight local time of the same day.
                            const localMidnightStart =
                              selectedTimeRange.startDate &&
                              DateTime.fromJSDate(selectedTimeRange.startDate, {
                                zone: 'UTC',
                              }).setZone('local', { keepLocalTime: true })
                            const localMidnightEnd =
                              selectedTimeRange.endDate &&
                              DateTime.fromJSDate(selectedTimeRange.endDate, {
                                zone: 'UTC',
                              }).setZone('local', { keepLocalTime: true })
                            setTimeRange(TimeRange.Custom, {
                              spaceCreatedAfter: localMidnightStart,
                              spaceCreatedBefore: localMidnightEnd.plus({ days: 1 }).startOf('day'),
                            })
                            setDateSelectionOpen(false)
                          }}
                        >
                          Apply Changes
                        </Button2>
                      </div>
                    </div>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
              <div className='flex flex-col items-start gap-3 relative mt-6 w-full mb-7'>
                <div className='flex items-center gap-2 relative self-stretch w-full flex-[0_0_auto]'>
                  <div className='gap-2 flex items-center relative w-full'>
                    <Input2
                      leadingIcon='search'
                      className='max-w-[200px]'
                      placeholder='Search'
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    {templateIds && (
                      <FilterDropdown
                        items={templates}
                        selectedIds={templateIds}
                        toggleSelection={toggleTemplateSelection}
                        setSelectedIds={setTemplateIds}
                        searchPlaceholder='Search templates'
                        labelForNoSelection='All templates'
                        labelForSelection='Templates'
                      />
                    )}
                    {actionPlanIds && (
                      <FilterDropdown
                        items={actionPlans}
                        selectedIds={actionPlanIds}
                        toggleSelection={toggleActionPlanSelection}
                        setSelectedIds={setActionPlanIds}
                        searchPlaceholder='Search action plans'
                        labelForNoSelection='All action plans'
                        labelForSelection='Action plans'
                      />
                    )}
                    {ownerIds && (
                      <FilterDropdown
                        items={sortedOwners}
                        selectedIds={ownerIds}
                        toggleSelection={toggleOwnerSelection}
                        setSelectedIds={setOwnerIds}
                        searchPlaceholder='Search owners'
                        labelForNoSelection='All owners'
                        labelForSelection='Owners'
                      />
                    )}
                    {milestoneIds && (
                      <FilterDropdown
                        items={milestones}
                        selectedIds={milestoneIds}
                        toggleSelection={toggleMilestoneSelection}
                        setSelectedIds={setMilestoneIds}
                        searchPlaceholder='Search milestones'
                        labelForNoSelection='All milestones'
                        labelForSelection='Milestones'
                      />
                    )}
                    <div className='ml-auto'>
                      {showResetButton && (
                        <Button2
                          variant={'outlined'}
                          leadingIcon='refresh'
                          className='mr-2 text-grey-s5 font-semibold'
                          onClick={resetFilters}
                        >
                          Reset
                        </Button2>
                      )}
                      <DropdownMenu open={filtersOpen} onOpenChange={setFiltersOpen}>
                        <DropdownMenuTrigger asChild>
                          <Button2 variant={'solid'} leadingIcon='options' className='ml-auto'>
                            Filters
                          </Button2>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent
                          className='w-[276px] flex flex-col gap-4 pt-4 pb-4 px-3'
                          align='end'
                        >
                          <FilterDropdown
                            items={sortedOwners}
                            selectedIds={ownerIds}
                            toggleSelection={toggleOwnerSelection}
                            setSelectedIds={setOwnerIds}
                            searchPlaceholder='Search owners'
                            labelForNoSelection={
                              hasSpacesAllAdministrationPermission ? 'All owners' : 'My spaces'
                            }
                            labelForSelection='Owners'
                            disabled={!hasSpacesAllAdministrationPermission}
                          />
                          <FilterDropdown
                            items={templates}
                            selectedIds={templateIds}
                            toggleSelection={toggleTemplateSelection}
                            setSelectedIds={setTemplateIds}
                            searchPlaceholder='Search templates'
                            labelForNoSelection='All templates'
                            labelForSelection='Templates'
                          />
                          <FilterDropdown
                            items={actionPlans}
                            selectedIds={actionPlanIds}
                            toggleSelection={toggleActionPlanSelection}
                            setSelectedIds={setActionPlanIds}
                            searchPlaceholder='Search action plans'
                            labelForNoSelection='All action plans'
                            labelForSelection='Action plans'
                          />
                          {milestonesFilterEnabled && (
                            <FilterDropdown
                              items={milestones}
                              selectedIds={milestoneIds}
                              toggleSelection={toggleMilestoneSelection}
                              setSelectedIds={setMilestoneIds}
                              searchPlaceholder='Search milestones'
                              labelForNoSelection='All milestones'
                              labelForSelection='Milestones'
                            />
                          )}
                          <div className='w-full flex justify-between flex-row px-1 py-1 items-center'>
                            <span className='text-xs font-semibold'>Include Archived Spaces</span>
                            <Switch
                              checked={includeArchivedSpaces}
                              onChange={setIncludeArchivedSpaces}
                            />
                          </div>
                          <div className='w-full flex justify-between flex-row px-1 py-1 items-center'>
                            <span className='text-xs font-semibold'>Show Archived Templates</span>
                            <Switch
                              checked={includeArchivedTemplates}
                              onChange={setIncludeArchivedTemplates}
                            />
                          </div>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </div>
                  </div>
                </div>
              </div>
              {!showNoMatchesState && (
                <>
                  <TabsContent value={GroupingLevel.Template}>
                    <div className='rounded-lg flex flex-col gap-4'>
                      <div className='flex flex-col gap-4 h-full'>
                        <span className='font-bold text-grey-s6'>Performance</span>

                        <div className='grid grid-cols-[144px_1fr_1fr] gap-4'>
                          {/* First column */}
                          <div className='space-y-4'>
                            <div className='bg-white p-4 rounded-lg flex flex-col'>
                              <div className='text-xl font-bold'>
                                {taskAnalyticsQuery.isLoading ? '\u00A0' : spaceCount}
                              </div>
                              <div className='text-xxs leading-[14px] font-normal text-grey-s5 m-0'>
                                Total Spaces
                              </div>
                            </div>
                            <div className='bg-white p-4 rounded-lg flex flex-col'>
                              <div className='text-xl font-bold m-0'>
                                {taskAnalyticsQuery.isLoading ? '\u00A0' : spacesCompletedCount}
                              </div>
                              <div className='text-xxs leading-[14px] font-normal text-grey-s5 m-0'>
                                Total Spaces completed
                              </div>
                            </div>
                          </div>

                          {/* Second column */}
                          <div className='flex flex-col bg-white rounded-lg gap-4 p-4'>
                            <div className='flex justify-between items-center'>
                              <div className='flex flex-col'>
                                <span className='text-xl font-bold'>
                                  {taskAnalyticsQuery.isLoading ? '\u00A0' : totalTasksCompleted}
                                </span>
                                <span className='text-xxs leading-[14px] font-normal text-grey-s5'>
                                  Total tasks completed
                                </span>
                              </div>

                              <div className='flex items-center'>
                                <Select
                                  value={groupBy}
                                  onValueChange={(value: GroupByPeriod) => setGroupBy(value)}
                                >
                                  <SelectTrigger className='w-[88px]'>
                                    <SelectValue placeholder='Group by' />
                                  </SelectTrigger>
                                  <SelectContent align='end'>
                                    <SelectItem value={GroupingPeriod.Week}>Week</SelectItem>
                                    <SelectItem value={GroupingPeriod.Month}>Month</SelectItem>
                                  </SelectContent>
                                </Select>
                              </div>
                            </div>
                            <TaskCompletionChart groupBy={groupBy} filter={filter} />
                          </div>

                          <div className='flex flex-col  gap-4 bg-white rounded-lg p-4'>
                            <div className='flex justify-between flex-col'>
                              <span className='text-xl font-bold'>
                                {taskAnalyticsQuery.isLoading ? '\u00A0' : totalAvgCompletionDays}
                              </span>
                              <span className='text-xxs leading-[14px] font-normal text-grey-s5'>
                                Average days to complete a space
                              </span>
                            </div>
                            {!taskAnalyticsQuery.isLoading && (
                              <AverageCompletionDaysChart data={taskAnalytics.items ?? []} />
                            )}
                            {taskAnalyticsQuery.isLoading && (
                              <Skeleton className='h-[144px] rounded-lg min-w-[300px]' />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className={analyticsTableGridClass}>
                        <ActionPlanAnalyticsTableHeader
                          startLabel='Template Name'
                          className='bg-white rounded-t-lg border-t border-l border-r border-grey-s2'
                        />
                        <ActionPlanAnalyticsTableGroup
                          isLoading={taskAnalyticsQuery.isLoading}
                          searchTerm={search}
                          templateTitle={undefined}
                          tables={[
                            {
                              id: 'template-summary-table',
                              column1Label: 'Template Name',
                              hideHeaderRow: true,
                              rows:
                                taskAnalytics?.items?.map((template) => ({
                                  id: template.templateId,
                                  originNodeId: template.templateNodeId,
                                  title: template.title,
                                  spaceCount: template.spaceCount,
                                  completedCount: template.completedCount,
                                  avgCompletionDays: template.avgCompletionDays,
                                  lowerQuartileDays: template.lowerQuartileDays,
                                  upperQuartileDays: template.upperQuartileDays,
                                  // Removed is always false for the template level (if template/archived
                                  // it does not appear in the analytics at all)
                                  removed: false,
                                  handleOpenDetailsClick: () => {
                                    setSearch('')
                                    setGroupingLevel(GroupingLevel.ActionPlan)
                                    setTemplateIds(new Set([template.templateId]))
                                  },
                                  handleCellClick,
                                })) || [],
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </TabsContent>
                  <TabsContent value={GroupingLevel.ActionPlan}>
                    <div className={analyticsTableGridClass}>
                      <ActionPlanAnalyticsTableHeader className='bg-background' />
                      {taskAnalyticsQuery.isLoading && (
                        <div
                          className={cn(
                            'rounded-lg bg-white/65 grid grid-cols-subgrid col-span-full mb-4 border border-grey-s2',
                          )}
                        >
                          <div className='p-4'>
                            <Skeleton className='h-4 rounded-lg' />
                          </div>
                          <div className='p-4'>
                            <Skeleton className='h-4 rounded-lg' />
                          </div>
                          <div className='p-4'>
                            <Skeleton className='h-4 rounded-lg' />
                          </div>
                          <div className='p-4'>
                            <Skeleton className='h-4 rounded-lg' />
                          </div>
                          <div className='p-4'>
                            <Skeleton className='h-4 rounded-lg' />
                          </div>
                          <div className='p-4'>
                            <Skeleton className='h-4 rounded-lg' />
                          </div>
                          <div className='p-4'>
                            <Skeleton className='h-4 rounded-lg w-[118px]' />
                          </div>
                        </div>
                      )}
                      {taskAnalytics?.items?.map((template) => (
                        <ActionPlanAnalyticsTableGroup
                          isLoading={taskAnalyticsQuery.isLoading}
                          key={template.templateId}
                          actionPlanBlockId={template.templateId}
                          searchTerm={groupingLevel === GroupingLevel.ActionPlan ? search : ''}
                          actionPlanTitle={template.title}
                          tables={[
                            {
                              id: template.templateId,
                              column1Label: 'Action Plan Name',
                              rows: template.actionPlans.map((actionPlan) => ({
                                id: actionPlan.originNodeId,
                                originNodeId: actionPlan.originNodeId,
                                title: actionPlan.title,
                                spaceCount: actionPlan.spaceCount,
                                completedCount: actionPlan.completedCount,
                                avgCompletionDays: actionPlan.avgCompletionDays,
                                lowerQuartileDays: actionPlan.lowerQuartileDays,
                                upperQuartileDays: actionPlan.upperQuartileDays,
                                removed: actionPlan.removed,
                                handleOpenDetailsClick: () => {
                                  setSearch('')
                                  setGroupingLevel(GroupingLevel.Milestone)
                                  setActionPlanIds(new Set([actionPlan.originNodeId]))
                                },
                                handleCellClick,
                              })),
                            },
                          ]}
                        />
                      ))}
                    </div>
                  </TabsContent>
                  <TabsContent value={GroupingLevel.Milestone}>
                    <div className={analyticsTableGridClass}>
                      <ActionPlanAnalyticsTableHeader className='bg-background' />
                      {taskAnalyticsQuery.isLoading && (
                        <div
                          className={cn(
                            'rounded-lg bg-white/65 grid grid-cols-subgrid col-span-full mb-4 border border-grey-s2',
                          )}
                        >
                          <div className='p-4'>
                            <Skeleton className='h-4 rounded-lg' />
                          </div>
                          <div className='p-4'>
                            <Skeleton className='h-4 rounded-lg' />
                          </div>
                          <div className='p-4'>
                            <Skeleton className='h-4 rounded-lg' />
                          </div>
                          <div className='p-4'>
                            <Skeleton className='h-4 rounded-lg' />
                          </div>
                          <div className='p-4'>
                            <Skeleton className='h-4 rounded-lg' />
                          </div>
                          <div className='p-4'>
                            <Skeleton className='h-4 rounded-lg' />
                          </div>
                          <div className='p-4'>
                            <Skeleton className='h-4 rounded-lg w-[118px]' />
                          </div>
                        </div>
                      )}
                      {!taskAnalyticsQuery.isLoading &&
                        taskAnalytics?.items?.map((template) =>
                          template.actionPlans.map((actionPlan) => (
                            <ActionPlanAnalyticsTableGroup
                              isLoading={taskAnalyticsQuery.isLoading}
                              key={actionPlan.originNodeId}
                              searchTerm={groupingLevel === GroupingLevel.Milestone ? search : ''}
                              actionPlanTitle={actionPlan.title}
                              templateTitle={template.title}
                              actionPlanBlockId={actionPlan.originNodeId}
                              tables={[
                                {
                                  id: actionPlan.originNodeId,
                                  column1Title: undefined,
                                  column1Label: 'Milestone Name',
                                  rows: actionPlan.milestones.map((milestone) => ({
                                    id: milestone.originNodeId,
                                    originNodeId: milestone.originNodeId,
                                    title: milestone.title,
                                    spaceCount: milestone.spaceCount,
                                    completedCount: milestone.completedCount,
                                    avgCompletionDays: milestone.avgCompletionDays,
                                    lowerQuartileDays: milestone.lowerQuartileDays,
                                    upperQuartileDays: milestone.upperQuartileDays,
                                    removed: milestone.removed,
                                    handleOpenDetailsClick: () => {
                                      setSearch('')
                                      setGroupingLevel('task')
                                      setMilestoneIds(new Set([milestone.originNodeId]))
                                    },
                                    handleCellClick,
                                  })),
                                },
                              ]}
                            />
                          )),
                        )}
                    </div>
                  </TabsContent>
                  <TabsContent value='task'>
                    <div className={analyticsTableGridClass}>
                      <ActionPlanAnalyticsTableHeader className='bg-background' />
                      {taskAnalyticsQuery.isLoading && (
                        <div
                          className={cn(
                            'rounded-lg bg-white/65 grid grid-cols-subgrid col-span-full mb-4 border border-grey-s2',
                          )}
                        >
                          <div className='p-4'>
                            <Skeleton className='h-4 rounded-lg' />
                          </div>
                          <div className='p-4'>
                            <Skeleton className='h-4 rounded-lg' />
                          </div>
                          <div className='p-4'>
                            <Skeleton className='h-4 rounded-lg' />
                          </div>
                          <div className='p-4'>
                            <Skeleton className='h-4 rounded-lg' />
                          </div>
                          <div className='p-4'>
                            <Skeleton className='h-4 rounded-lg' />
                          </div>
                          <div className='p-4'>
                            <Skeleton className='h-4 rounded-lg' />
                          </div>
                          <div className='p-4'>
                            <Skeleton className='h-4 rounded-lg w-[118px]' />
                          </div>
                        </div>
                      )}
                      {!taskAnalyticsQuery.isLoading &&
                        taskAnalytics?.items?.map((template) =>
                          template.actionPlans.map((actionPlan) => (
                            <ActionPlanAnalyticsTableGroup
                              isLoading={taskAnalyticsQuery.isLoading}
                              searchTerm={groupingLevel === 'task' ? search : ''}
                              key={actionPlan.originNodeId}
                              actionPlanTitle={actionPlan.title}
                              templateTitle={template.title}
                              actionPlanBlockId={actionPlan.originNodeId}
                              tables={actionPlan.milestones.map((milestone) => ({
                                id: milestone.originNodeId,
                                column1Title: `Milestone: ${milestone.title}`,
                                column1Label: 'Task Name',
                                defaultOpenActionLabel: 'Open Action Plan',
                                rows: milestone.tasks.map((task) => ({
                                  id: task.originNodeId,
                                  originNodeId: task.originNodeId,
                                  title: task.title,
                                  spaceCount: task.spaceCount,
                                  completedCount: task.completedCount,
                                  avgCompletionDays: task.avgCompletionDays,
                                  lowerQuartileDays: task.lowerQuartileDays,
                                  upperQuartileDays: task.upperQuartileDays,
                                  removed: task.removed,
                                  openActionLabel: task.removed ? 'Open Template' : undefined,
                                  openActionTrailingIcon: 'open-outline',
                                  handleOpenDetailsClick: () => {
                                    const url = new URL(location.origin)
                                    url.pathname = `/spaces/${template.templateNodeId}`
                                    if (!task.removed) {
                                      url.searchParams.set('block', actionPlan.originNodeId)
                                    }
                                    window.open(url.toString(), '_blank', 'noopener,noreferrer')
                                  },
                                  handleCellClick,
                                })),
                              }))}
                            />
                          )),
                        )}
                    </div>
                  </TabsContent>
                </>
              )}
            </Tabs>
            {showNoMatchesState && (
              <div className='flex flex-grow justify-center items-center'>
                <EmptyState icon={'search'}>
                  <EmptyStateHeader>
                    <EmptyStateTitle>No matches found!</EmptyStateTitle>
                    <EmptyStateDescription>
                      Try changing or clearing out your filters.
                    </EmptyStateDescription>
                  </EmptyStateHeader>
                  <EmptyStateAction onClick={resetFilters}>Reset filter</EmptyStateAction>
                </EmptyState>
              </div>
            )}
            {typeof taskAnalytics?.meta?.lastRefreshed !== 'undefined' && (
              <>
                {!!flagsQuery.flags?.['dashboard-action-plan-analytics-refresh'] && (
                  <div className='flex justify-end pb-7'>
                    <Button2
                      size={'small'}
                      variant={'ghost'}
                      onClick={() => refreshMutation.mutate()}
                    >
                      Last refresh:{' '}
                      {taskAnalytics?.meta?.lastRefreshed
                        ? DateTime.fromISO(taskAnalytics.meta.lastRefreshed).toLocaleString(
                            DateTime.DATETIME_SHORT,
                          )
                        : 'never'}
                    </Button2>
                  </div>
                )}
                {!flagsQuery.flags?.['dashboard-action-plan-analytics-refresh'] && (
                  <div className='flex justify-end pb-7 text-xxs text-grey-s5 font-normal'>
                    <div>
                      Last refresh:{' '}
                      {taskAnalytics?.meta?.lastRefreshed
                        ? DateTime.fromISO(taskAnalytics.meta.lastRefreshed).toLocaleString(
                            DateTime.DATETIME_SHORT,
                          )
                        : 'never'}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default ActionPlanAnalytics
