import { RouterProvider } from '@tanstack/react-router'
import { router } from './modules/navigation/Router'
import { OverlayProvider, SpacesApiProvider } from '@valuecase/ui-components'
import { Auth, createAuthReturnPath, useAuthState } from './auth/auth'
import { useEffect, useMemo, useState } from 'react'
import mixpanel from 'mixpanel-browser'
import { useCurrentTenantQuery } from './modules/tenant/hooks/useReadTenant'
import { spacesApi } from './api/spacesApi/spacesAPIclient'
import { TemplateGenerationProvider } from './modules/templates/TemplateCreator/TemplateGeneration/context/TemplateGenerationContext'
import { OnboardingFlow } from './modules/onboarding/OnboardingFlow'
import AccountLockedAlert from './modules/billing/AccountLockedDialog'

const mixpanelEnabled =
  import.meta.env.VITE_MIXPANEL_ENABLED === 'true' && import.meta.env.VITE_MIXPANEL_ID

export function App() {
  const authState = useAuthState()
  const tenant = useCurrentTenantQuery()

  //redirect to login page if !authorized
  useEffect(() => {
    if (!authState.loading && !authState.authorized) {
      Auth.login(createAuthReturnPath())
    }
  }, [authState.authorized, authState.loading])

  //Init Mixpanel
  useEffect(() => {
    if (mixpanelEnabled) {
      mixpanel.init(import.meta.env.VITE_MIXPANEL_ID, {
        //TODO it's not really an id. It's a token.
        debug: true,
        disable_persistence: true,
        ignore_dnt: true,
        // Enable session recording for 10% of users
        record_sessions_percent: 10,
        record_max_ms: 2 * 60 * 60 * 1000, // 2 hours
      })
    }
  }, [])

  useEffect(() => {
    if (import.meta.env.VITE_MIXPANEL_ENABLED === 'true' && authState.authorized) {
      const mixpanelId = authState.email?.includes('@valuecase.')
        ? `internal_${authState.sub}`
        : authState.sub
      mixpanel.identify(mixpanelId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.authorized])

  const showSelfSignUp = useMemo(() => {
    const tenantData = tenant?.tenant?.payload

    if (!tenantData) return false

    return tenantData?.isSelfSignup && !tenantData?.isOnboarded
  }, [tenant?.tenant?.payload])

  const [mixpanelRecordingStarted, setMixpanelRecordingStarted] = useState(false)

  useEffect(() => {
    if (mixpanelRecordingStarted || !showSelfSignUp) {
      return
    }
    if (!mixpanelEnabled) {
      return
    }

    // Start recording user session when self-signup is enabled
    mixpanel.start_session_recording()
    setMixpanelRecordingStarted(true)
  }, [mixpanelRecordingStarted, showSelfSignUp])

  if (authState.loading || !authState.authorized || !tenant.isFetched) {
    return <></>
  }

  return (
    <SpacesApiProvider spacesApi={spacesApi}>
      <TemplateGenerationProvider>
        <OverlayProvider>
          {showSelfSignUp ? (
            <OnboardingFlow isOpen={true} />
          ) : (
            <RouterProvider basepath='dashboard' router={router} />
          )}
          <AccountLockedAlert />
        </OverlayProvider>
      </TemplateGenerationProvider>
    </SpacesApiProvider>
  )
}
