import { FunctionComponent, ReactNode, SVGProps, useMemo } from 'react'
import styled from 'styled-components'
import { RequireAtLeastOne } from '@valuecase/ui-components'
import DOMPurify from 'dompurify'

export type SettingsStackRowProps = RequireAtLeastOne<
  {
    title: string
    description?: string
    image?: string | FunctionComponent<SVGProps<SVGSVGElement>>
    leftChild: ReactNode
    rightChild: ReactNode
  },
  'title' | 'leftChild'
>

export function SettingsStackRow({
  title,
  description,
  image,
  leftChild,
  rightChild,
}: SettingsStackRowProps) {
  const leftContent: ReactNode = useMemo(() => {
    if (!title) return leftChild
    if (image) {
      const TypedImage = image as FunctionComponent<SVGProps<SVGSVGElement>>
      return (
        <div className={'w-140 flex flex-row'}>
          <div
            style={{ width: '44px', height: '44px' }}
            className={
              'shrink-0 mr-4 p-2 bg-white border-solid border border-grey-s2 rounded-lg flex'
            }
          >
            {typeof image === 'string' ? <img src={image} /> : <TypedImage />}
          </div>
          <div>
            <h4 className={'text-sm font-bold'}>{title}</h4>
            {description && (
              <p
                className={'text-sm -6 text-grey-s5 leading-6'}
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }}
              ></p>
            )}
          </div>
        </div>
      )
    }
    return (
      <div className={'w-140'}>
        <h4 className={'text-sm font-bold leading-6'}>{title}</h4>
        <p className={'text-sm -6 text-grey-s5 leading-6'}>{description}</p>
      </div>
    )
  }, [title, leftChild, image, description])

  return (
    <StyledSettingsStackRow>
      {leftContent}
      <div className={'flex items-center pr-4'}>{rightChild}</div>
    </StyledSettingsStackRow>
  )
}

const StyledSettingsStackRow = styled.div`
  border-top: 1px solid var(--theme-grey-s2);
  padding: 40px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
