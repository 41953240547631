import {
  DragEvent,
  forwardRef,
  HTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { IonIcon } from '../../Icons/IonIcon'
import { cn } from '../../lib'
import { MIME_TYPES_WITH_PREVIEW } from '../../utils'

export type TAcceptedFileTypesGroup = {
  acceptedFilesDescription: string
  mimeTypes: string[]
}

export const useFileUploadAcceptedMIMETypeGroups = () => {
  const { t } = useTranslation()

  const previewTypes = useMemo<TAcceptedFileTypesGroup>(() => {
    const acceptedFilesDescription = t('components.FileUploadArea.supportedText', {
      defaultValue: 'Supported: Images, Videos, Word, PowerPoint and PDF files',
    })

    return {
      acceptedFilesDescription,
      mimeTypes: MIME_TYPES_WITH_PREVIEW,
    }
  }, [t])

  return {
    previewTypes,
  }
}

function getFiles(ev: DragEvent<HTMLDivElement>): File[] {
  if (!ev.dataTransfer) {
    return []
  }
  if (ev.dataTransfer.items) {
    return Array.from(ev.dataTransfer.items)
      .filter((item) => item.kind === 'file')
      .map((item) => {
        const file = item.getAsFile()
        if (file === null) {
          throw Error('file is null, not expected')
        }

        return file
      })
  }
  return Array.from(ev.dataTransfer.files)
}

export function fileTooLarge(file: File, maxFileSizeInMB = 250): boolean {
  return file.size > maxFileSizeInMB * 1_048_576 // 250 MB
}

export const FileDropArea = forwardRef<
  HTMLDivElement,
  {
    onFileSelected: (files: File[]) => void
    showFileTooLargeError?: boolean
    acceptedFileTypes?: TAcceptedFileTypesGroup
    maxFileSizeInMB?: number
    multiple?: boolean
  } & HTMLAttributes<HTMLDivElement>
>(
  (
    {
      onFileSelected,
      children,
      showFileTooLargeError: showFileTooLargeErrorProp,
      acceptedFileTypes,
      className,
      maxFileSizeInMB = 250,
      multiple = false,
      ...props
    },
    ref,
  ) => {
    const { t } = useTranslation()
    const [isDragging, setIsDragging] = useState(false)
    const [showFileTooLargeError, setShowFileTooLargeError] = useState(false)
    const [showFileTypeNotSupportedError, setShowFileTypeNotSupportedError] = useState(false)

    useEffect(() => {
      if (showFileTooLargeErrorProp) {
        setShowFileTooLargeError(true)
      }
    }, [showFileTooLargeErrorProp])

    const checkFilesAndMaybeEmit = useCallback(
      (files: File[]) => {
        let hasFileTooLarge = false
        let hasInvalidFileType = false

        files.forEach((file) => {
          if (fileTooLarge(file, maxFileSizeInMB)) {
            hasFileTooLarge = true
          }

          if (acceptedFileTypes) {
            const fileTypeValid = acceptedFileTypes.mimeTypes.some((fileType) =>
              file.type.match(fileType),
            )
            if (!fileTypeValid) {
              hasInvalidFileType = true
            }
          }
        })

        setShowFileTooLargeError(hasFileTooLarge)
        setShowFileTypeNotSupportedError(hasInvalidFileType)

        if (!hasFileTooLarge && !hasInvalidFileType) {
          onFileSelected(files)
        }
      },
      [acceptedFileTypes, maxFileSizeInMB, onFileSelected],
    )

    return (
      <>
        <div
          ref={ref}
          role={props.onClick ? 'button' : undefined}
          className={cn(
            'flex flex-col p-4 md:p-10 gap-4 border border-dashed rounded-xl border-grey-s3',
            'items-center justify-center text-center transition-colors',

            {
              'hover:bg-primary-s1 hover:border-primary-s4': props.onClick,
              'bg-primary-s2 border-primary-s4': isDragging,
            },
            className,
          )}
          onDragStart={(e) => {
            e.preventDefault()
            setIsDragging(true)
          }}
          onDragOver={(e) => {
            e.preventDefault()
            setIsDragging(true)
          }}
          onDragLeave={(e) => {
            e.preventDefault()
            setIsDragging(false)
          }}
          onDragCapture={(e) => {
            e.preventDefault()
            setIsDragging(false)
          }}
          onDragExit={(e) => {
            e.preventDefault()
            setIsDragging(false)
          }}
          onDrop={(event) => {
            event.preventDefault()
            const files = getFiles(event)
            if (files.length === 0) {
              setIsDragging(false)
              return
            }

            checkFilesAndMaybeEmit(files)
            setIsDragging(false)
          }}
          {...props}
        >
          {isDragging ? (
            <>
              <IonIcon name={'c_pizza-slice'} className={'w-8 h-8 text-primary-s4'} />
              <h4 className={'text-primary-s4 text-xs font-semibold'}>
                {t('components.FileUploadArea.dropText', {
                  defaultValue: "Drop it like it's hot",
                })}
              </h4>
            </>
          ) : (
            <>
              {!!children && children}
              {!children && (
                <div className={'flex flex-col gap-2 items-center'}>
                  <IonIcon name={'add'} className='w-8 h-8 text-primary-s4' />
                  <div className={'flex font-semibold text-xs'}>
                    <p>
                      {t('components.FileUploadArea.title', {
                        defaultValue: 'Drop your file here or',
                      })}{' '}
                      <span className={'text-primary-s5 hover:underline'}>
                        {t('components.FileUploadArea.browseButtonLabel', {
                          defaultValue: 'browse',
                        })}
                      </span>
                    </p>
                  </div>
                </div>
              )}
              <p className={'text-xs text-grey-s4'}>
                {!!acceptedFileTypes && (
                  <>
                    {acceptedFileTypes.acceptedFilesDescription}
                    <br />
                  </>
                )}
                {t('components.FileUploadArea.fileSizeText', {
                  defaultValue: `Maximum file size of ${maxFileSizeInMB}MB`,
                  maxFileSizeInMB,
                })}
              </p>
            </>
          )}
        </div>
        {showFileTooLargeError && (
          <div className={'flex gap-2 mt-2'}>
            <div className={'w-4'}>
              <IonIcon name={'warning'} className={'w-4 h-4 text-warning-s4 flex-grow'} />
            </div>
            <p className={'text-xs text-grey-s5'}>
              {t('components.FileUploadArea.tooLargeError.text', {
                defaultValue: `The file you selected exceeds the maximum allowed file size of ${maxFileSizeInMB} MB. If you try to upload a video call recording, consider using the embed function.`,
                maxFileSizeInMB,
              })}{' '}
              <a
                href='https://help.valuecase.de/en/articles/102805-how-can-i-add-a-zoom-meeting-recording-to-valuecase'
                target='_blank'
                rel='noreferrer'
                className='text-primary-s5 font-semibold hover:underline'
              >
                {t('components.FileUploadArea.tooLargeError.learnMoreLinkLabel', {
                  defaultValue: 'Learn more about embedding.',
                })}
              </a>
            </p>
          </div>
        )}

        {showFileTypeNotSupportedError && (
          <div className={'flex gap-2 mt-2 items-center'}>
            <div className={'w-4 flex items-center'}>
              <IonIcon name={'warning'} className={'w-4 h-4 text-warning-s4 flex-grow'} />
            </div>
            <p className={'text-xs text-grey-s5'}>
              {t('components.FileUploadArea.notSupportedErrorText', {
                defaultValue: 'The file type you selected is not supported.',
              })}{' '}
              {acceptedFileTypes?.acceptedFilesDescription}
            </p>
          </div>
        )}
      </>
    )
  },
)

FileDropArea.displayName = 'FileDropArea'

export function FileUploadArea({
  onFileSelected,
  acceptedFileTypes,
  maxFileSizeInMB,
  className,
  multiple = false,
}: {
  onFileSelected: (files: File[]) => void
  acceptedFileTypes?: TAcceptedFileTypesGroup
  maxFileSizeInMB?: number
  className?: string
  multiple?: boolean
}) {
  const { t } = useTranslation()
  const filesInputRef = useRef<HTMLInputElement>(null)

  const [showFileTooLargeError, setShowFileTooLargeError] = useState(false)

  const checkFilesAndMaybeEmit = useCallback(
    (files: File[]) => {
      const hasFileTooLarge = files.some((file) => fileTooLarge(file, maxFileSizeInMB))
      setShowFileTooLargeError(hasFileTooLarge)

      if (!hasFileTooLarge) {
        onFileSelected(files)
      }
    },
    [maxFileSizeInMB, onFileSelected],
  )

  return (
    <>
      <input
        className={'hidden'}
        ref={filesInputRef}
        type='file'
        multiple={multiple}
        accept={acceptedFileTypes ? acceptedFileTypes.mimeTypes.join(',') : '*'}
        onChange={(e) => {
          if (e.currentTarget.files && e.currentTarget.files.length > 0) {
            checkFilesAndMaybeEmit(Array.from(e.currentTarget.files))
          }
        }}
      />
      <FileDropArea
        multiple={multiple}
        onClick={() => filesInputRef.current?.click()}
        onFileSelected={onFileSelected}
        showFileTooLargeError={showFileTooLargeError}
        acceptedFileTypes={acceptedFileTypes}
        maxFileSizeInMB={maxFileSizeInMB}
        className={className}
      >
        <div className={'flex flex-col gap-2 items-center'}>
          <IonIcon name={'cloud-upload-outline'} className='w-8 h-8 text-primary-s4' />
          <div className={'flex font-semibold text-xs'}>
            <p>
              <span className='hidden md:block'>
                {t('components.FileUploadArea.title', {
                  defaultValue: 'Drop your file here or',
                })}{' '}
              </span>
              <span className='md:hidden'>
                {t('components.FileUploadArea.mobileTitle', {
                  defaultValue: 'Click here to upload file or',
                })}{' '}
              </span>
              <span className={'text-primary-s5 hover:underline'}>
                {t('components.FileUploadArea.browseButtonLabel', {
                  defaultValue: 'browse',
                })}
              </span>
            </p>
          </div>
        </div>
      </FileDropArea>
    </>
  )
}
