import { IonIcon } from '@valuecase/ui-components'

export const GoToDesktopStep = () => {
  return (
    <div className='flex flex-col gap-4 text-center'>
      <IonIcon name='c_virtual-meeting' className='w-full h-[120px] ' />

      <h2 className='text-sm'>Your First Space is Being Created!</h2>
      <h1 className='text-lg font-bold'>Valuecase editor works best on desktop. </h1>

      <p className='text-sm text-left text-grey-s5'>
        Please complete your account setup on a desktop device:
      </p>

      <ul className='text-sm text-left text-grey-s5 list-decimal list-inside ml-2'>
        <li>Open your email inbox.</li>
        <li>Locate the “Set Your Valuecase Password” email and click the link inside.</li>
        <li>Log in to your Valuecase account.</li>
      </ul>
      <p className='text-sm text-left text-grey-s5'>
        Once you&apos;re in, start exploring your first Valuecase space and discover other features!
      </p>
    </div>
  )
}
