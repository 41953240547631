import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from '../../lib/utils'
import { AnchorHTMLAttributes, ButtonHTMLAttributes, forwardRef, useMemo } from 'react'
import { IonIcon } from '../../Icons/IonIcon'

const buttonVariants = cva(
  cn(
    'inline-flex items-center justify-center whitespace-nowrap rounded-lg text-xs font-semibold ',
    'ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 ',
    'focus-visible:ring-offset-2 focus-visible:ring-primary-s4 disabled:pointer-events-none ',
    'disabled:cursor-not-allowed',
  ),
  {
    variants: {
      variant: {
        solid:
          'bg-primary-s5 text-white hover:bg-primary-s6 disabled:bg-grey-s2 disabled:text-grey-s4',
        outlined:
          'bg-grey-s1 text-grey-s6 border-solid border border-grey-s4 hover:bg-grey-s2 disabled:text-grey-s3 disabled:border-grey-s3 disabled:bg-grey-s1',
        subtle:
          'bg-primary-s2 text-primary-s5 hover:bg-primary-s3 disabled:bg-grey-s1 disabled:text-grey-s3',
        ghost:
          'bg-transparent text-grey-s5 font-semibold hover:text-grey-s6 disabled:text-grey-s3 hover:bg-transparent',
        plain: 'bg-transparent text-grey-s6 hover:bg-grey-s2 disabled:text-grey-s3',
        'plain-highlighted':
          'bg-transparent text-primary-s5 hover:bg-primary-s2 disabled:text-grey-s3',
        danger:
          'bg-warning-s5 text-white hover:bg-warning-s6 disabled:bg-grey-s2 disabled:text-grey-s4',
        'danger-plain': 'bg-transparent text-warning-s5 hover:bg-warning-s2 disabled:text-grey-s3',
        link: 'bg-transparent text-primary-s5 hover:underline disabled:text-grey-s3 leading-1',
        success:
          'bg-success-s5 text-white hover:bg-success-s6 disabled:bg-grey-s2 disabled:text-grey-s4',
        secondary:
          'bg-grey-s2 text-grey-s5 hover:bg-grey-s3 disabled:bg-grey-s1 disabled:text-grey-s3',
      },
      size: {
        default: 'h-9 py-2.5 px-3 gap-2',
        small: 'h-7 min-w-7 py-[7px] px-2 gap-1',
        'x-small': 'h-6 min-w-6 p-[6px] gap-1',
        // empty string is intentional
        link: '',
      },
    },
    defaultVariants: {
      variant: 'solid',
      size: 'default',
    },
  },
)

interface ButtonBaseProps extends VariantProps<typeof buttonVariants> {
  leadingIcon?: string
  trailingIcon?: string
}

const ButtonBaseChildren = ({
  leadingIcon,
  trailingIcon,
  size,
  variant,
  children,
}: ButtonBaseProps & { children: React.ReactNode }) => {
  const iconClassName = useMemo(
    () =>
      cn('flex-shrink-0', {
        'w-4 h-4': size === 'default',
        'w-3 h-3': size === 'small' || size === 'x-small',
      }),
    [size],
  )

  return (
    <>
      {leadingIcon && <IonIcon className={iconClassName} name={leadingIcon} />}
      {children}
      {trailingIcon && <IonIcon className={iconClassName} name={trailingIcon} />}
    </>
  )
}

export interface Button2Props extends ButtonBaseProps, ButtonHTMLAttributes<HTMLButtonElement> {
  asChild?: boolean
}

const Button2 = forwardRef<HTMLButtonElement, Button2Props>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      children,
      leadingIcon,
      trailingIcon,
      onClick,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button'

    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        onClick={(e) => {
          onClick && onClick(e)
        }}
        {...props}
      >
        <ButtonBaseChildren
          leadingIcon={leadingIcon}
          trailingIcon={trailingIcon}
          size={size}
          variant={variant}
        >
          {children}
        </ButtonBaseChildren>
      </Comp>
    )
  },
)
Button2.displayName = 'Button'

const ButtonLink = forwardRef<
  HTMLAnchorElement,
  ButtonBaseProps & AnchorHTMLAttributes<HTMLAnchorElement>
>(({ className, variant, size, children, leadingIcon, trailingIcon, ...props }, ref) => {
  return (
    <a className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props}>
      <ButtonBaseChildren
        leadingIcon={leadingIcon}
        trailingIcon={trailingIcon}
        size={size}
        variant={variant}
      >
        {children}
      </ButtonBaseChildren>
    </a>
  )
})

ButtonLink.displayName = 'ButtonLink'

export { Button2, ButtonLink, buttonVariants }
