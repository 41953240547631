import { useEffect } from 'react'
import '../../assets/modalStyles.css'
import { useTrackEvent } from '../../../../mixpanel/useTrackEvent'
import { Button2 } from '@valuecase/ui-components/src/components/ui/button'
import { useOpenCreateSpaceModal } from '../../hooks/useOpenCreateSpaceModal'

const TriggerCreateSpaceFlow = ({
  shouldOpenModalExternally,
}: {
  shouldOpenModalExternally?: boolean
}) => {
  const { trackEvent } = useTrackEvent()
  const { openCreateSpaceModal } = useOpenCreateSpaceModal()

  // NOTE: In DEV, this will trigger two times the modal due to how useEffect is ran in DEV.
  // NOTE: In PROD, this will trigger one time the modal.
  useEffect(() => {
    if (!shouldOpenModalExternally) {
      return
    }
    openCreateSpaceModal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldOpenModalExternally])

  return (
    <Button2
      className={'mt-2'}
      data-intercom-target='Create New Space Button'
      trailingIcon={'add'}
      onClick={() => {
        trackEvent({
          event: 'spaces-newspace-open',
          eventProperties: {
            indexPageName: 'Spaces',
          },
        })
        openCreateSpaceModal()
      }}
    >
      Create New Space
    </Button2>
  )
}

export default TriggerCreateSpaceFlow
