import { cn } from '../../lib/utils'
import * as React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'

const textareaVariants = cva(
  cn(
    'flex w-full rounded-md border bg-white',
    'h-fit',
    'placeholder:text-grey-s4',
    'focus:outline-none focus:ring-4 focus:ring-blue-s3 focus:ring-opacity-50 focus:ring-offset-0',
    'hover:ring-4 hover:ring-blue-s3 hover:ring-opacity-50 hover:ring-offset-0',
    'disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-grey-s2 disabled:hover-none disabled:ring-0',
    'transition-shadow',
  ),
  {
    variants: {
      size: {
        default: 'min-h-[36px] px-3 py-2 text-xs',
        large: 'min-h-[44px] px-3 py-2 text-sm',
      },
      variant: {
        default: 'border-grey-s2 focus:border-blue-s4',
        error: 'border-warning-s5 focus:border-warning-s5 focus:ring-warning-s3',
      },
    },
    defaultVariants: {
      size: 'default',
      variant: 'default',
    },
  },
)

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    VariantProps<typeof textareaVariants> {}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, size, variant, ...props }, ref) => {
    return (
      <textarea
        className={cn(textareaVariants({ size, variant }), className)}
        ref={ref}
        {...props}
      />
    )
  },
)
Textarea.displayName = 'Textarea'

export { Textarea }
