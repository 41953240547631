import { spacesApi } from '@/api/spacesApi/spacesAPIclient'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { TTemplateGenerationRequest, TTemplateGenerationRequestStatus } from '@valuecase/common'
import { useCallback, useMemo, useState } from 'react'

const fetchTemplateGenerationStatus = async (requestId: string | null) => {
  if (!requestId) {
    return
  }
  const response = await spacesApi.get<TTemplateGenerationRequest>(
    `/templates/generate/ai/${requestId}/status`,
  )
  return response.data
}

const shouldContinuePolling = (data: TTemplateGenerationRequest | undefined) => {
  if (!data) {
    return true
  }

  const shouldCreateSpace = !!data.spaceCreationProperties

  return ![
    shouldCreateSpace
      ? TTemplateGenerationRequestStatus.SPACE_CREATION_COMPLETED
      : TTemplateGenerationRequestStatus.COMPLETED,
    TTemplateGenerationRequestStatus.ERROR,
    TTemplateGenerationRequestStatus.FALLBACK_USED,
  ].includes(data.status)
}

export const usePollTemplateGenerationRequest = ({ requestId }: { requestId: string | null }) => {
  const [shouldCreateSpace, setSetShouldCreateSpace] = useState(false)
  const [latestStatus, setLatestStatus] = useState<TTemplateGenerationRequestStatus | null>(null)
  const queryClient = useQueryClient()
  const handleSuccess = useCallback(
    (data: TTemplateGenerationRequest) => {
      setLatestStatus(data?.status ?? null)
      setSetShouldCreateSpace(!!data?.spaceCreationProperties)
      if (
        data.status === TTemplateGenerationRequestStatus.COMPLETED ||
        data.status === TTemplateGenerationRequestStatus.FALLBACK_USED
      ) {
        queryClient.invalidateQueries(['readTemplates'])
      }
    },
    [queryClient],
  )

  const { data, error, isFetching } = useQuery({
    queryKey: ['templateGeneration', requestId],
    queryFn: () => fetchTemplateGenerationStatus(requestId),
    enabled: Boolean(requestId),
    onSuccess: handleSuccess,
    refetchInterval: (data) => (shouldContinuePolling(data) ? 1000 : false),
    retry: false,
  })

  const isPolling = useMemo(() => {
    if (isFetching) {
      return true
    }

    if (latestStatus === null) {
      return false
    }

    const isTerminalStatus = [
      TTemplateGenerationRequestStatus.ERROR,
      TTemplateGenerationRequestStatus.FALLBACK_USED,
    ].includes(latestStatus)

    if (isTerminalStatus) {
      return false
    }

    if (shouldCreateSpace) {
      return latestStatus !== TTemplateGenerationRequestStatus.SPACE_CREATION_COMPLETED
    }

    return latestStatus !== TTemplateGenerationRequestStatus.COMPLETED
  }, [isFetching, latestStatus, shouldCreateSpace])

  return {
    data,
    error,
    isPolling,
  }
}
