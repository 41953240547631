import BillingApi from '@/api/resources/BillingApi'
import { useMutation } from '@tanstack/react-query'

export function useCreateCustomerPortalSession() {
  const mutation = useMutation({
    mutationFn: BillingApi.createCustomerPortalSession,
    onSuccess: (data) => {
      if (data.payload.url) {
        window.location.href = data.payload.url
      }
    },
    onError: (error) => {
      console.error('Error redirecting to customer portal:', error)
    },
  })

  return {
    createCustomerPortalSession: mutation.mutate,
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    error: mutation.error,
  }
}
