import Topbar from '../topbar/Topbar'

import { S } from './Layout.styled'
import { Outlet } from '@tanstack/react-router'
import IntercomComponent from '../Intercom/Intercom'
import { Sidebar } from '../sidebar/Sidebar'

const Layout = () => {
  return (
    <S.Layout>
      <Topbar />
      <S.SecondRow>
        <Sidebar />
        <S.Main>
          <S.Margin>
            <Outlet />
          </S.Margin>
        </S.Main>
      </S.SecondRow>
      <IntercomComponent hideDefaultLauncher={false} />
    </S.Layout>
  )
}

export default Layout
