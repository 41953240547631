import { cn, IonIcon } from '@valuecase/ui-components'
import { useState, useEffect, useMemo } from 'react'

export const useStoreCollapsableState = (
  id: string,
  defaultOpen: boolean = true,
): [boolean, (value: boolean) => void] => {
  const STORAGE_KEY = useMemo(() => `seller-${id}Open`, [id])

  // Retrieve the initial value from localStorage or default to true
  const getInitialValue = (): boolean => {
    const storedValue = localStorage.getItem(STORAGE_KEY)
    return storedValue ? JSON.parse(storedValue) : defaultOpen
  }

  const [open, setOpen] = useState<boolean>(getInitialValue)

  // Update localStorage whenever the state changes
  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(open))
  }, [STORAGE_KEY, open])

  return [open, setOpen]
}

export const CollapsibleChevron = ({ open, className }: { open: boolean; className?: string }) => {
  const iconName = useMemo(() => {
    return open ? 'chevron-up' : 'chevron-down'
  }, [open])

  return <IonIcon name={iconName} className={cn('w-4 h-4 transition-colors', className)} />
}
