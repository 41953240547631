import styled from 'styled-components'
import { useRef } from 'react'
import { IonIcon } from '@valuecase/ui-components'

export interface DropAreaBrowseContentProps {
  allowMultipleFiles?: boolean
  supportedMimeTypes: string
  supportedText: string
  onFilesSelected: (files: File) => Promise<void>
}

/**
 *
 * @deprecated Use `FileUploadArea` instead
 */
export default function FilesDropAreaBrowse({
  supportedMimeTypes,
  supportedText,
  allowMultipleFiles,
  onFilesSelected,
}: DropAreaBrowseContentProps) {
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  return (
    <BrowseContent>
      <IonIcon name={'file-tray-outline'} />

      <div className={'cta'}>
        Drop the file here or{' '}
        <span
          className={'action'}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            fileInputRef?.current?.click()
          }}
        >
          browse
        </span>
        <input
          ref={fileInputRef}
          type={'file'}
          style={{ display: 'none' }}
          multiple={allowMultipleFiles}
          accept={supportedMimeTypes}
          onInput={(e) => {
            e.preventDefault()
            const file = (e.target as HTMLInputElement)?.files?.[0]
            if (file) {
              onFilesSelected?.(file)
            }
          }}
        />
      </div>

      <div className={'supportedLabel'}>{'Supported' + ': ' + supportedText}</div>
    </BrowseContent>
  )
}

const BrowseContent = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 14px;
  justify-content: center;
  align-items: center;

  .ionicon {
    width: 32px;
    height: 32px;
    color: var(--theme-primary-s4);
  }

  font-size: 12px;
  line-height: 150%;

  .cta {
    font-weight: 600;
    color: var(--theme-grey-s6);
    text-align: center;
    white-space: nowrap;

    .action {
      color: var(--theme-primary-s5);
      transition: color 0.2s ease;
      outline: none;
      text-decoration: none;

      &:hover {
        color: var(--theme-primary-s6);
        cursor: pointer;
      }
    }
  }

  .supportedLabel {
    font-weight: 400;
    font-size: 10px;
    text-align: center;
  }
`
