import * as React from 'react'
import * as TabsPrimitive from '@radix-ui/react-tabs'
import { cn } from '../../lib/utils'
import { IonIcon } from '../../Icons/IonIcon'

const Tabs = TabsPrimitive.Root

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={cn('inline-flex h-10 items-center justify-center rounded-lg p-1 gap-1', className)}
    {...props}
  />
))
TabsList.displayName = TabsPrimitive.List.displayName

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn(
      'inline-flex items-center justify-center whitespace-nowrap p-3 text-xs shadow-none',
      'font-medium transition-all outline-none text-grey-s6 border-b-2 border-transparent border-solid',
      'disabled:pointer-events-none disabled:opacity-50 hover:text-primary-s5',
      'data-[state=active]:text-primary-s5 data-[state=active]:border-primary-s5',
      className,
    )}
    {...props}
  />
))
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName

const PanelTabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, children, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={cn(
      'inline-flex h-9 items-center justify-center rounded-lg bg-grey-s2 p-1',
      className,
    )}
    {...props}
  >
    {children}
  </TabsPrimitive.List>
))

PanelTabsList.displayName = TabsPrimitive.List.displayName

const PanelTabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger> & { leadingIcon?: string }
>(({ className, leadingIcon, children, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn(
      'inline-flex flex-1 items-center justify-center gap-2',
      'h-7 px-3 py-1.5',
      'text-xs font-semibold text-grey-s5 whitespace-nowrap rounded outline-none transition-all',
      'enabled:hover:text-grey-s6 disabled:pointer-events-none disabled:opacity-50',
      'data-[state=active]:bg-white data-[state=active]:shadow-sm',
      {
        'p-2': !children && leadingIcon,
      },
      className,
    )}
    {...props}
  >
    {leadingIcon && <IonIcon name={leadingIcon} className='w-4 h-4' />}
    {children}
  </TabsPrimitive.Trigger>
))

PanelTabsTrigger.displayName = TabsPrimitive.Trigger.displayName

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn('mt-2 focus-visible:outline-none', className)}
    {...props}
  />
))
TabsContent.displayName = TabsPrimitive.Content.displayName

export { Tabs, TabsList, TabsTrigger, TabsContent, PanelTabsList, PanelTabsTrigger }
