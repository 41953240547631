import { AnimatePresence, motion } from 'framer-motion'
import { cn, DottedPagination, IonIcon, useBreakpoints } from '@valuecase/ui-components'
import { ReactNode } from 'react'

export type FlowStep = {
  id: string
  component: React.ComponentType<any>
  title?: string
  titleSubtext?: string
  icon?: string
  leftButton?: ReactNode
  rightButton?: ReactNode
  takesUpFullScreen?: boolean
  componentProps?: Record<string, any>
}

type FullScreenFlowProps = {
  isOpen: boolean
  onClose?: () => void
  currentStep: number
  steps: FlowStep[]
  componentProps: Record<string, any>
  className?: string
}

export const FullScreenFlow = ({
  isOpen,
  onClose,
  currentStep,
  steps,
  componentProps,
  className,
}: FullScreenFlowProps) => {
  const CurrentStageComponent = steps[currentStep].component
  const currentStepConfig = steps[currentStep]

  const { isMobile } = useBreakpoints()

  if (!isOpen) {
    return null
  }

  // Early return for full-screen components
  if (currentStepConfig.takesUpFullScreen) {
    return (
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={cn('fixed inset-0 z-50', className)}
        >
          <CurrentStageComponent {...componentProps} {...currentStepConfig.componentProps} />
        </motion.div>
      </AnimatePresence>
    )
  }

  return (
    <AnimatePresence>
      {isMobile && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={cn('fixed w-full inset-0 z-50 overflow-y-auto', className)}
        >
          <div className='flex min-h-full flex-col px-6 pt-8 pb-10 gap-8'>
            <div className='flex flex-grow flex-col gap-8'>
              {onClose && (
                <div className='absolute top-4 right-4 p-4 hover:cursor-pointer'>
                  <IonIcon name='close' onClick={onClose} />
                </div>
              )}

              <div className='flex items-center gap-2 flex-col text-center'>
                <AnimatePresence mode='wait'>
                  <div className='h-10'>
                    <motion.div
                      key={`icon-${currentStep}`}
                      initial={{ scale: 0.5, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                      exit={{ scale: 0.5, opacity: 0 }}
                      transition={{ duration: 0.2 }}
                    >
                      <IonIcon
                        name={currentStepConfig.icon}
                        className='text-ai-s4-from w-10 h-10'
                      />
                    </motion.div>
                  </div>
                </AnimatePresence>

                <AnimatePresence mode='wait'>
                  <motion.span
                    key={`title-${currentStep}`}
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -10, opacity: 0 }}
                    className='font-bold'
                  >
                    {currentStepConfig.title}
                  </motion.span>
                </AnimatePresence>

                <AnimatePresence mode='wait'>
                  <motion.span
                    key={`subtext-${currentStep}`}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    className='text-grey-s5 text-sm'
                  >
                    {currentStepConfig.titleSubtext}
                  </motion.span>
                </AnimatePresence>
              </div>

              <motion.div>
                <CurrentStageComponent {...componentProps} {...currentStepConfig.componentProps} />
              </motion.div>
            </div>

            <div className={'flex [&>*]:flex-1 gap-3'}>
              {currentStepConfig.leftButton}
              {currentStepConfig.rightButton}
            </div>
          </div>
        </motion.div>
      )}

      {!isMobile && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={cn('fixed inset-0 z-50 overflow-y-auto', className)}
        >
          <div className='w-full mx-auto sm:px-8 md:px-16 lg:px-28 h-xxs:py-4 h-xs:py-6 h-sm:py-12 h-md:py-20 flex flex-col min-h-screen'>
            {onClose && (
              <div className='absolute top-16 right-16 p-4 hover:cursor-pointer'>
                <IonIcon name='close' onClick={onClose} />
              </div>
            )}

            <div className='flex-1 min-h-26 flex items-center justify-center'>
              <div className='w-full max-w-4xl flex flex-col'>
                <div
                  className={cn(
                    'flex flex-col gap-2 items-center',
                    'mb-6',
                    'h-xs:mb-4',
                    'h-sm:mb-6',
                    'h-md:mb-8',
                    'h-lg:mb-20',
                  )}
                >
                  <AnimatePresence mode='wait'>
                    <motion.div
                      key={`icon-${currentStep}`}
                      initial={{ scale: 0.5, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                      exit={{ scale: 0.5, opacity: 0 }}
                      transition={{ duration: 0.2 }}
                    >
                      <IonIcon
                        name={currentStepConfig.icon}
                        className='text-ai-s4-from w-10 h-10 hidden h-xxs:hidden h-xs:hidden h-sm:block h-md:block'
                      />
                    </motion.div>
                  </AnimatePresence>

                  <AnimatePresence mode='wait'>
                    <motion.span
                      key={`title-${currentStep}`}
                      initial={{ y: 10, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: -10, opacity: 0 }}
                      className='text-2xl font-extrabold text-grey-s6'
                    >
                      {currentStepConfig.title}
                    </motion.span>
                  </AnimatePresence>

                  <AnimatePresence mode='wait'>
                    <motion.span
                      key={`subtext-${currentStep}`}
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                      className='text-grey-s5 text-sm'
                    >
                      {currentStepConfig.titleSubtext}
                    </motion.span>
                  </AnimatePresence>
                </div>

                <motion.div>
                  <CurrentStageComponent
                    {...componentProps}
                    {...currentStepConfig.componentProps}
                  />
                </motion.div>

                <div
                  className={cn(
                    'flex items-center justify-between',
                    'mt-6',
                    'h-xs:mt-4',
                    'h-sm:mt-6',
                    'h-md:mt-8',
                    'h-lg:mt-20',
                  )}
                >
                  <div className='w-40'>{currentStepConfig.leftButton}</div>
                  <DottedPagination
                    page={currentStep}
                    pageCount={steps.filter((step) => !step.takesUpFullScreen).length}
                  />
                  <div className='w-40 flex justify-end'>{currentStepConfig.rightButton}</div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
