import { TTemplateGenerationRequestStatus } from '@valuecase/common'
import { Button2, IonIcon, Progress } from '@valuecase/ui-components'
import { useCallback, useMemo } from 'react'
import { useTemplateGeneration } from './context/TemplateGenerationContext'
import { useTemplateGenerationProgress } from '../../hooks/useTemplateGenerationProgress'

const statusMessages = {
  [TTemplateGenerationRequestStatus.PREVIEW]: 'Initializing template generation...',
  [TTemplateGenerationRequestStatus.STARTED]: 'Analyzing your requirements...',
  [TTemplateGenerationRequestStatus.TEMPLATE_GENERATOR_RESPONSE_RECEIVED]:
    'Creating template structure...',
  [TTemplateGenerationRequestStatus.BLOCK_CREATOR_RESPONSE_RECEIVED]:
    'Validating template structure...',
  [TTemplateGenerationRequestStatus.OUTPUT_VALIDATION]: 'Creating template...',
  [TTemplateGenerationRequestStatus.COMPLETED]: 'Template generation completed!',
  [TTemplateGenerationRequestStatus.SPACE_CREATION_STARTED]: 'Creating space...',
  [TTemplateGenerationRequestStatus.SPACE_CREATION_COMPLETED]: 'Space created!',
  [TTemplateGenerationRequestStatus.SPACE_CREATION_FAILED]: 'Failed to create space',
  [TTemplateGenerationRequestStatus.ERROR]: 'An error occurred during generation',
} as const

const TemplateGenerationLoading = ({ onClose }: { onClose: () => void }) => {
  const { isGenerating, generationData, isPolling } = useTemplateGeneration()
  const progress = useTemplateGenerationProgress(generationData?.status)

  // Computed properties for better readability
  const shouldCreateSpace = useMemo(() => {
    return Boolean(generationData?.spaceCreationProperties)
  }, [generationData?.spaceCreationProperties])

  const createdSpaceId = useMemo(() => {
    return generationData?.spaceCreationProperties?.spaceId
  }, [generationData?.spaceCreationProperties?.spaceId])

  const createdTemplateId = useMemo(() => {
    return generationData?.createdTemplateRootNodeId
  }, [generationData?.createdTemplateRootNodeId])

  const currentStatus = useMemo(() => {
    return generationData?.status
  }, [generationData?.status])

  // URL generation
  const getSpaceUrl = useCallback((spaceOrTemplateId: string, token?: string | null): string => {
    const url = new URL(location.origin)
    url.pathname = `/spaces/${spaceOrTemplateId}`
    if (token) {
      url.searchParams.append('token', token || '')
    }
    return url.href
  }, [])

  // Navigation handler
  const navigateToDestination = useCallback(() => {
    if (shouldCreateSpace && createdSpaceId) {
      window.location.href = getSpaceUrl(
        createdSpaceId,
        generationData?.spaceCreationProperties?.authToken,
      )
    } else if (createdTemplateId) {
      window.location.href = getSpaceUrl(createdTemplateId)
    }
  }, [
    shouldCreateSpace,
    createdSpaceId,
    createdTemplateId,
    getSpaceUrl,
    generationData?.spaceCreationProperties?.authToken,
  ])

  // Status computations
  const statusMessage = useMemo(() => {
    if (!currentStatus) {
      return 'Initializing template generation...'
    }
    return statusMessages[currentStatus as keyof typeof statusMessages]
  }, [currentStatus])

  const isFinished = useMemo(() => {
    const isTemplateFinished =
      currentStatus === TTemplateGenerationRequestStatus.COMPLETED &&
      createdTemplateId &&
      !isGenerating &&
      !isPolling

    const isSpaceFinished =
      currentStatus === TTemplateGenerationRequestStatus.SPACE_CREATION_COMPLETED &&
      createdSpaceId &&
      !isGenerating &&
      !isPolling

    return (shouldCreateSpace && isSpaceFinished) || (!shouldCreateSpace && isTemplateFinished)
  }, [currentStatus, createdTemplateId, isGenerating, isPolling, createdSpaceId, shouldCreateSpace])

  const isError = useMemo(() => {
    const hasTemplateError = currentStatus === TTemplateGenerationRequestStatus.ERROR
    const hasSpaceError = currentStatus === TTemplateGenerationRequestStatus.SPACE_CREATION_FAILED
    return (hasTemplateError || hasSpaceError) && !isPolling
  }, [currentStatus, isPolling])

  // UI elements
  const successMessage = useMemo(() => {
    return shouldCreateSpace ? 'Your space is ready' : 'Your template is ready'
  }, [shouldCreateSpace])

  const actionButtonText = useMemo(() => {
    return shouldCreateSpace ? 'Open Space' : 'Open Template'
  }, [shouldCreateSpace])

  return (
    <div className='h-28 w-118 flex items-center justify-center p-6 bg-gradient-to-br from-ai-s1-from via-ai-s1-via to-ai-s1-to rounded-md shadow-md'>
      {isError && (
        <div className='absolute top-4 right-4 cursor-pointer' onClick={onClose}>
          <IonIcon name='close' className='text-grey-s5 w-4 h-4 hover:text-grey-s6' />
        </div>
      )}

      {!isFinished && !isError && isPolling && (
        <div className='flex flex-col w-full'>
          <span className='text-sm font-semibold text-grey-s6'>
            Creating
            {shouldCreateSpace ? ' space' : ' template'}...
          </span>
          <div className='flex w-full'>
            <Progress className='w-full' variant={'ai'} value={progress} />
          </div>
          <span className='text-xs text-grey-s5'>{statusMessage}</span>
        </div>
      )}

      {isFinished && !isError && !isPolling && (
        <div className='flex w-full items-center justify-between'>
          <div className='flex items-center gap-2'>
            <IonIcon name='checkmark-circle' className='text-success-s5 w-6 h-6' />
            <span className='text-sm font-semibold text-grey-s6'>{successMessage}</span>
          </div>
          <Button2 onClick={navigateToDestination}>{actionButtonText}</Button2>
        </div>
      )}

      {isError && (
        <div className='flex w-full items-center justify-start'>
          <IonIcon name='bug-outline' className='text-error-s5 w-6 h-6' />
          <span className='text-sm font-semibold text-grey-s6'>An error occurred</span>
        </div>
      )}
    </div>
  )
}

export default TemplateGenerationLoading
