import styled from 'styled-components'
import classNames from 'classnames'
import React, { useState } from 'react'
import { IonIcon } from '@valuecase/ui-components'

function getFilesFromDropEvent(event: React.DragEvent<HTMLDivElement>): File | null {
  if (!event.dataTransfer) {
    return null
  }

  if (event.dataTransfer.items) {
    return Array.from(event.dataTransfer.items)
      .filter((item) => item.kind === 'file')
      .filter((item) => item.type.startsWith('image/'))
      .map((item) => item.getAsFile() as File)[0]
  }

  return Array.from(event.dataTransfer.files)[0]
}

export interface FilesDropAreaProps {
  children?: React.ReactNode
  fillParent?: boolean
  allowMultipleFiles?: boolean
  onFilesDropped: (files: File) => Promise<void>
}

/**
 *
 * @deprecated Use `FileUploadArea` instead
 */
export default function FilesDropArea({
  fillParent,
  children,
  onFilesDropped,
}: FilesDropAreaProps) {
  const [draggedOver, setDraggedOver] = useState(false)

  return (
    <Container
      onDragStart={(e) => {
        e.preventDefault()
        setDraggedOver(true)
      }}
      onDragOver={(e) => {
        e.preventDefault()
        setDraggedOver(true)
      }}
      onDragLeave={(e) => {
        e.preventDefault()
        setDraggedOver(false)
      }}
      onDragCapture={(e) => {
        e.preventDefault()
        setDraggedOver(false)
      }}
      onDragExit={(e) => {
        e.preventDefault()
        setDraggedOver(false)
      }}
      onDrop={(e) => {
        e.preventDefault()
        setDraggedOver(false)
        const file = getFilesFromDropEvent(e)
        if (file) {
          onFilesDropped?.(file)
        }
      }}
      className={classNames({ fillParent, draggedOver })}
    >
      {!draggedOver && <>{children}</>}

      {draggedOver && (
        <DropItLikeItsHotContainer>
          <IonIcon name={'c_pizza-slice'} />
          <span>Drop it like it&apos;s hot</span>
        </DropItLikeItsHotContainer>
      )}
    </Container>
  )
}

const Container = styled.div`
  background: white;
  border: 1px dashed var(--theme-grey-s3);
  border-radius: 8px;
  padding: 24px;

  transition:
    background 0.2s ease,
    border 0.2s ease;

  &.fillParent {
    width: 100%;
    height: 100%;
  }

  &.draggedOver {
    background: var(--theme-primary-s2);
    border: 1px dashed var(--theme-primary-s4);
  }
`

const DropItLikeItsHotContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  row-gap: 14px;

  pointer-events: none;

  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: var(--theme-primary-s4);

  .ionicon {
    color: var(--theme-primary-s4);
    width: 30px;
    height: 30px;
  }
`
